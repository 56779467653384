<template>
  <div>
    <TextHeadingDual
      v-if="user_answers == null"
      class="sticky -top-6 bg-gray-50 py-4"
      style="z-index: 2"
      :main-text="'Editor'"
      :secondary-text="currentVersionText"
    >
      <template #options>
        <div v-if="false" class="w-28 flex-shrink-0">
          <div class="flex items-start gap-2 pt-0.5">
            <ProgressIcon type="regular" :progress="pointsProgress" percentage-pos="right" class="flex-1" />
            <div
              class="whitespace-nowrap flex-shrink-0 text-right leading-3 pt-0.5"
              :class="{ 'text-red-500': currentTotalPoints > totalExamPoints }"
            >
              {{ currentTotalPoints }} / <b>{{ totalExamPoints }}</b>
              <div class="text-gray-500 text-xs">pontos</div>
            </div>
          </div>
        </div>

        <div v-if="false" class="flex gap-2">
          <fw-tag
            v-for="lang in originalInstance.languages"
            :key="'lang_selector_' + lang"
            :type="language == lang ? 'light-primary-border-box' : 'light-border-box'"
            size="xs"
            class="flex gap-1 cursor-pointer uppercase"
            @click.native="changeLanguage(lang)"
          >
            {{ lang }}
          </fw-tag>
        </div>
        <b-tooltip v-if="editable" :active="errorSaving" label="Dados não guardados">
          <fw-button type="xlight" @click.native="saveForm">
            <fw-icon-loading v-if="savingData" class="w-5 h-5 mr-2" />
            <fw-icon-alert v-else-if="errorSaving" class="h-5 w-5 mr-2 text-red-500"></fw-icon-alert>
            <fw-icon-cloud
              v-else
              class="h-5 w-5 mr-2"
              :class="{ 'text-primary animate-pulse': dirtyData, 'text-gray-300': !dirtyData }"
            ></fw-icon-cloud>
            <div :class="{ 'text-gray-500': !dirtyData }">Guardar</div>
          </fw-button>
        </b-tooltip>

        <fw-button v-if="editable" type="xlight" @click.native="cancelEditor">Pré-visualizar</fw-button>
      </template>
    </TextHeadingDual>

    <!-- Index of sections -->
    <div
      v-if="false"
      class="fixed right-6 w-36 top-48 pt-3 flex-col justify-end hidden xl:flex gap-1"
      style="z-index: 21"
    >
      <span class="text-gray-400 text-sm mb-2 font-semibold text-right pr-2">Índice</span>
      <template v-for="page in pages">
        <button
          v-for="section in page.schema"
          :key="form.key + 'bt_nav_' + section.key"
          class="relative text-gray-600 text-sm px-2 py-2 inline-flex flex items-center font-bold transition-colors duration-150 rounded-lg hover:opacity-75 focus:outline-none justify-end"
          @click="goToSection(section.key)"
        >
          <div
            v-show="typeof section.title[language] === 'undefined' || section.title[language].length === 0"
            class="empty_nav_bt bt_nav_lateral"
          >
            ---
          </div>
          <div
            v-show="!(typeof section.title[language] === 'undefined' || section.title[language].length === 0)"
            class="bt_nav_lateral"
          >
            {{ section.title[language] }}
          </div>
        </button>
      </template>
    </div>

    <div v-if="user_answers === null && !loading">
      <fw-message v-if="missingOptions" type="warning" class="mb-2" padding>
        Não se esqueça de assinalar as opções certas em todas as perguntas de escolha múltipla.</fw-message
      ><fw-message v-else-if="false && currentTotalPoints > totalExamPoints" type="warning" class="mb-2" padding>
        O total de pontos atribuídos às perguntas não pode ser superior ao total de pontos do exame. Verifique as
        pontuações das perguntas.
      </fw-message>
      <fw-message v-else-if="missingPoints" type="warning" class="mb-2" padding>
        Não se esqueça de atribuir pontos a todas as perguntas.
      </fw-message>
      <fw-message v-if="errors && errors.length > 0" type="warning" class="mb-2" padding>
        <div class="font-semibold">Foram detetados os seguintes erros:</div>
        <ul class="list-disc list-inside">
          <li v-for="(error, e) in errors" :key="e">{{ error }}</li>
        </ul>
      </fw-message>

      <div class="mb-5 rounded-xl px-5 py-3 bg-gray-200">
        <fw-label>Instruções</fw-label>
        <FormattedText
          v-if="form.instructions"
          :id="'form_instructions'"
          :key="'form_instructions_' + language"
          v-model="form.instructions[language]"
          :hello="form.instructions[language]"
          :html="form.instructions[language]"
          :disabled="!editable"
          class="mb-1.5 w-full -mt-1"
          :placeholder="'Insira aqui as instruções e regras da avaliação.'"
          @input="dataChanged('form')"
        ></FormattedText>
        <div v-else class="text-sm text-gray-500">Sem conteúdo definido no idioma selecionado.</div>
      </div>
    </div>

    <div v-else-if="user_answers != null">
      <div ref="form_main_body" class="flex flex-col pb-2 mb-2 px-0 md:px-0 py-2 items-top">
        <div class="flex items-start gap-2">
          <button
            v-if="showBackButton"
            class="relative opacity-70 group hover:opacity-100 bg-gray-200 bg-opacity-80 text-gray-500 font-bold justify-center p-2 my-2 text-sm rounded-lg focus:outline-none"
            @click="goBack"
          >
            <span class="sr-only">Voltar à página anterior</span
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="fill-current w-3 h-3"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                d="M5.828 7l2.536 2.536L6.95 10.95 2 6l4.95-4.95 1.414 1.414L5.828 5H13a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H5.828z"
              ></path>
            </svg>
          </button>
          <div
            class="text-3xl text-gray-900 flex-1 font-semibold items-center gap-1 rounded px-2 py-1 pl-0 bg-transparent border-transparent outline-none"
          >
            <TextHeadingDual :main-text="instance.title[language]" :secondary-text="currentVersionText" />
          </div>
          <fw-button
            v-if="user_answers != null && canCorrectExam && showSaveCorrection"
            type="primary"
            :disabled="saving"
            @click.native="saveEvaluation(false)"
          >
            {{ saving ? 'A guardar...' : 'Guardar correção' }}
          </fw-button>
        </div>

        <div class="ml-1 text-gray-500 text-sm pb-2 font-semibold">Respostas de:</div>
        <div class="rounded-lg shadow-sm bg-white p-2 pb-3 pt-2.5 overflow-hidden flex flex-col gap-1">
          <Person
            :key="user_answers.user.key"
            no-style
            :paddingless="true"
            class="pl-1 pr-0"
            :person="user_answers.user"
            :selectable="false"
            :clickable="false"
          >
            <template #options>
              <div class="flex gap-3 justify-end">
                <div class="flex-shrink-0 w-32 text-right">
                  {{ user_answers.summary && user_answers.summary.answers ? user_answers.summary.answers : '--' }}
                  <div class="text-xs text-gray-600">Respostas</div>
                </div>
                <div v-if="false" class="flex-shrink-0 w-36 text-right">
                  {{
                    user_answers.summary && user_answers.summary.correctAnswers
                      ? user_answers.summary.correctAnswers
                      : '--'
                  }}
                  <div class="text-xs text-gray-600">Respostas certas</div>
                </div>
                <div class="flex-shrink-0 w-24 text-right">
                  {{ user_answers.summary && user_answers.summary.scaleScore ? user_answers.summary.scaleScore : '--' }}
                  <div class="text-xs text-gray-600">Pontuação</div>
                </div>
              </div>
            </template>
          </Person>
        </div>
      </div>

      <fw-message v-if="user_answers.summary.state == 'needs_manual_correction'" type="info" class="text-sm mb-2"
        >Este exame necessita de correção manual.</fw-message
      >
    </div>

    <div class="pb-32" @click.self="clickBackground">
      <div v-for="(page, p) in pages" :key="form.key + '_page_' + p + '_' + language">
        <div v-if="(cover === null && p > 0) || cover !== null" class="page-separator">
          <div class="title">Página {{ p + 1 }}</div>
        </div>
        <div
          v-for="(section, s) in page.schema"
          :key="form.key + 'section_' + p + '_' + s"
          :ref="'section_' + section.key"
          class="page-section relative"
        >
          <div class="flex gap-3 items-start pr-3">
            <div class="flex-1">
              <input
                v-if="editable || (!editable && section.title[language] && section.title[language].length > 0)"
                v-model="section.title[language]"
                class="w-full bg-transparent border-transparent text-2xl font-semibold px-4 md:px-0 py-1 outline-none text-gray-600"
                placeholder="Título da secção"
                :disabled="!editable"
                type="text"
                @input="dataChanged(p)"
              />
              <FormattedText
                v-if="section?.description"
                v-model="section.description[language]"
                :html="section.description[language]"
                :disabled="!editable"
                class="mb-1.5 w-full -mt-1 px-4 md:px-0"
                :placeholder="'Insira aqui o enunciado da secção.'"
                @input="dataChanged(p)"
              ></FormattedText>
            </div>
            <b-dropdown
              v-if="editable && page.schema.length > 1"
              class="mt-1"
              :scrollable="true"
              :max-height="200"
              aria-role="list"
              position="is-bottom-left"
            >
              <template #trigger="{ active }">
                <fw-button type="xlight" class="h-8 mt-1 text-gray-600"><fw-icon-more /></fw-button>
              </template>

              <b-dropdown-item aria-role="listitem">
                <div
                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                  @click="removeSection(p, s)"
                >
                  Eliminar secção
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div
            class="mt-3 text-gray-800"
            :class="{
              'mb-20': s === page.schema.length - 1 && isMobile,
              'mb-6': s < page.schema.length - 1 && !isMobile,
              'mb-16': s < page.schema.length - 1 && isMobile
            }"
          >
            <div v-if="section.content.length === 0" class="real-white rounded-xl px-5 pb-5 pt-3 relative box-question">
              <div class="text-gray-300 text-lg text-center py-5">Secção sem perguntas</div>
              <div v-if="editable" class="mb-3 text-sm text-gray-600 text-center">Comece por criar uma pergunta:</div>
              <div v-if="editable" class="flex gap-3 justify-center text-gray-700 items-center">
                <fw-button type="xlight" @click.native="addContent(p, s, null)"> Adicionar pergunta </fw-button>
              </div>
            </div>
            <!--
              :data-index="index"
              group-name="column"
              :get-child-payload="(itemIndex) => getChildPayload(index, itemIndex)"
              :should-accept-drop="
              (src, payload) => getShouldAcceptDrop(index, src, payload)
              :get-child-payload="getQuestionPayload(p, s)"
              " -->
            <Container
              v-else
              group-name="questions"
              drop-class="card-ghost-drop"
              :drop-placeholder="dropPlaceholderOptions"
              :should-animate-drop="() => true"
              :get-child-payload="index => getChildPayload(index, p, s)"
              drag-handle-selector=".drag-handle"
              class="flex flex-col gap-9 pb-5"
              @drop="onDropSection(p, s, $event)"
            >
              <Draggable
                v-for="(input, i) in section.content"
                :key="'input_' + input.key"
                class="real-white rounded-xl px-5 pb-5 pt-3 relative box-question"
              >
                <div v-if="editable" class="drag-handle hidden sm:flex top-5">
                  <svg
                    class="fill-current h-3 w-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 10 16"
                    width="10"
                    height="16"
                  >
                    <path
                      fill-opacity="0.13"
                      d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
                    />
                  </svg>
                </div>
                <div class="question-container flex">
                  <div class="flex-1">
                    <div
                      class="flex w-full items-start gap-2"
                      :class="{ 'absolute pr-2': input.type === 'image_direct' }"
                    >
                      <div class="flex-1 flex flex-col items-strech h-full">
                        <div class="flex text-sm text-gray-500 mt-1 mb-3 gap-5">
                          <div
                            class="bg-gray-900 rounded-full w-8 h-8 text-base text-white leading-8 text-center font-bold nohighlight"
                          >
                            {{ i + 1 }}
                          </div>
                          <div class="flex-1 text-gray-400 flex items-center gap-2">
                            {{ $t(input.type) }}
                          </div>
                          <div class="absolute -top-0.5 right-0 flex gap-2">
                            <b-dropdown :scrollable="true" :max-height="200" aria-role="list" position="is-bottom-left">
                              <template #trigger="{ active }">
                                <fw-button type="transparent" size="sm" class="text-gray-600 text-sm">
                                  <fw-icon-more class="h-5 w-5" />
                                </fw-button>
                              </template>

                              <b-dropdown-item v-if="editable && input.type == 'text_area'" aria-role="listitem">
                                <div
                                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                                  @click="changeMaxChar(p, s, i)"
                                >
                                  Alterar limite da resposta
                                </div>
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-if="canIgnoreQuestions && user_answers == null && input.ignored"
                                aria-role="listitem"
                              >
                                <div
                                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                                  @click="revertIgnoreQuestion(p, s, i)"
                                >
                                  Reverter anulação da pergunta
                                </div>
                              </b-dropdown-item>
                              <b-dropdown-item
                                v-if="canIgnoreQuestions && user_answers == null && input.ignored !== true"
                                aria-role="listitem"
                              >
                                <div
                                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                                  @click="ignoreQuestion(p, s, i)"
                                >
                                  Anular pergunta
                                </div>
                              </b-dropdown-item>
                              <b-dropdown-item v-if="editable" aria-role="listitem">
                                <div
                                  class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                                  @click="removeInput(p, s, i)"
                                >
                                  {{
                                    input.type === 'text_content' ||
                                    input.type === 'image_direct' ||
                                    input.type === 'image'
                                      ? 'Eliminar conteúdo'
                                      : 'Eliminar pergunta'
                                  }}
                                </div>
                              </b-dropdown-item>

                              <div
                                class="flex justify-start items-center font-semibold text-gray-400 bg-gray-100 mx-2 p-2 rounded-lg text-sm my-2"
                              >
                                #{{ input.key }}
                              </div>
                            </b-dropdown>
                            <div
                              v-if="
                                input.type !== 'text_content' &&
                                  input.type !== 'image_direct' &&
                                  input.type !== 'image' &&
                                  (form.options.pointsCorrectInput > 0 || (input.score !== null && input.score > 0))
                              "
                              class="text-right top-20 sticky"
                            >
                              <!-- Manual points -->
                              <div
                                v-if="user_answers !== null && input.type !== 'multiple_choice'"
                                class="flex items-center gap-2"
                              >
                                <input
                                  v-model="user_corrections[page.key][input.key].points"
                                  :disabled="input.ignored == true || !canCorrectExam"
                                  class="points_input question-points"
                                  type="number"
                                  min="0"
                                  :max="form.options.pointsCorrectInput"
                                  inputmode="numeric"
                                  @input="correctionChanged(p)"
                                />
                                / {{ form.options.pointsCorrectInput }}
                              </div>
                              <div v-else-if="input.score !== null">
                                <input
                                  v-model="input.score"
                                  :disabled="!editable"
                                  class="points_input question-points"
                                  type="number"
                                  inputmode="numeric"
                                  @input="dataChanged(p)"
                                />
                              </div>
                              <div v-else class="points_input question-points">
                                {{ form.options.pointsCorrectInput }}
                              </div>
                              <div class="text-gray-500 text-xs mb-2 mt-0.5">valores</div>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="input.ignored || input.optionsChanged"
                          class="mt-3 mb-1 flex flex-col gap-1 font-medium text-sm"
                        >
                          <div
                            v-if="input.ignored"
                            class="bg-red-600 text-white rounded-md px-3 py-2 flex justify-between items-center"
                          >
                            <div class="font-bold">Pergunta anulada</div>
                            <div>
                              Decisão:
                              {{ input.ignored_options == 'no_score' ? 'remover pontuação' : 'dar pontuação a todos' }}
                            </div>
                          </div>
                          <div v-if="input.optionsChanged" class="bg-yellow-300 text-opacity-70 rounded-md px-3 py-2">
                            As opcões de resposta foram alteradas em {{ input.optionsChangedDate | formatDateTime }}.
                          </div>
                        </div>

                        <div
                          v-if="input.file == null && user_answers == null && editable"
                          class="bg-gray-50 rounded-xl w-full mt-2 mb-2"
                        >
                          <Uploader
                            :is-docked="true"
                            :layout="'simple'"
                            :label="'Adicione uma imagem à pergunta'"
                            :reference-id="'upload_' + p + '_' + s + '_' + i + '_' + input.key"
                            :is-custom="true"
                            :limit="1"
                            allowed="images"
                            :clear-after="true"
                            bucket-code="files"
                            bucket-type="file"
                            input-id="upload"
                            class="text-gray-500"
                            @upload="uploadFileToQuestion(p, s, i, $event)"
                          />
                        </div>
                        <div
                          v-else-if="input.file != null"
                          class="overflow-hidden rounded-xl mb-2 mt-2 bg-gray-200 image_direct min-h-16 relative"
                        >
                          <div v-if="editable" class="absolute top-5 right-5">
                            <fw-button
                              type="glass"
                              size="sm"
                              class="font-semibold text-xs"
                              @click.native="removeFile(p, s, i, $event)"
                            >
                              <fw-icon-trash class="text-red-600 h-5 w-5"></fw-icon-trash>
                            </fw-button>
                          </div>
                          <img
                            v-if="input.file.token && input.file.type == 'image'"
                            :src="ServiceStorage.getImageViewUrl(input.file, null, null)"
                            class="w-full"
                          />
                        </div>

                        <div class="flex gap-5">
                          <FormattedText
                            v-if="input.instructions && input.type !== 'image_direct'"
                            :id="input.key"
                            v-model="input.instructions[language]"
                            class="flex-1 text-lg font-semibold"
                            :disabled="!editable"
                            :placeholder="
                              input.type === 'multiple_choice'
                                ? 'Escreva aqui a pergunta'
                                : input.type === 'upload_file'
                                ? 'Escreva aqui as instruções de envio dos ficheiros'
                                : input.type === 'text_content'
                                ? 'Escreva aqui qualquer coisa'
                                : 'Escreva aqui a pergunta'
                            "
                            @input="dataChanged(p)"
                          ></FormattedText>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1" style="margin-top: 3.5rem">
                    <div
                      v-if="input.type === 'text_area' && user_answers == null"
                      class="relative bg-white ring-1 ring-gray-900/5 rounded px-4 py-4 cursor-not-allowed"
                      style="z-index: 1"
                    >
                      <div
                        class="absolute inset-x-0 inset-y-4 border-t border-b border-gray-100 pointer-events-none"
                      ></div>
                      <div
                        class="absolute inset-x-4 inset-y-0 border-l border-r border-gray-100 pointer-events-none"
                      ></div>
                      <div
                        class="slighty-gray-color p-3 pb-10 text-gray-400 flex flex-col group gap-1 items-start w-full"
                      >
                        {{ input.placeholder[language] }}
                        <div v-if="input.maxChars && input.maxChars > 0" class="mt-1 font-semibold text-xs">
                          Reposta limitada a {{ input.maxChars }} caracteres
                        </div>
                        <div
                          v-if="false"
                          class="inline-block relative ml-0.5 flex items-center gap-1 group-hover:bg-opacity-0 opacity-30 group-hover:opacity-70 text-sm bg-gray-200 bg-opacity-80 text-gray-500 font-bold flex items-center justify-center text-sm rounded focus:outline-none"
                          style="height: 20px; width: 20px; padding: 2px"
                        >
                          <svg
                            class="fill-current"
                            style="height: 16px; width: 16px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="input.type === 'text_area' && user_answers != null"
                      class="relative bg-white ring-1 ring-gray-900/5 rounded px-4 py-4 text-gray-800 break-all"
                      style="z-index: 1"
                    >
                      {{
                        user_answers &&
                        user_answers.answers &&
                        user_answers.answers[page.key] &&
                        user_answers.answers[page.key][input.key] &&
                        user_answers.answers[page.key][input.key].length > 0
                          ? user_answers.answers[page.key][input.key]
                          : 'Sem resposta'
                      }}
                    </div>

                    <div
                      v-else-if="input.type === 'upload_file' && user_answers != null"
                      class="bg-white flex rounded-md items-center flex-col border border-gray-100 text-gray-600"
                    >
                      <RecordFileEntry
                        v-for="file in user_answers.answers[page.key][input.key]"
                        :key="file.key"
                        :can-edit="false"
                        :allow-classified="false"
                        :file="file"
                        :can-download="true"
                        :can-remove="false"
                        @download="downloadFile(file)"
                      />
                    </div>

                    <div
                      v-else-if="input.type === 'upload_file' && user_answers == null"
                      class="bg-white cursor-not-allowed flex gap-2 rounded-md items-center flex-col p-4 border border-dashed border-gray-300 text-gray-600"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                        class="fill-current w-8 h-8 opacity-50"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path
                          d="M12 12.586l4.243 4.242-1.415 1.415L13 16.415V22h-2v-5.587l-1.828 1.83-1.415-1.415L12 12.586zM12 2a7.001 7.001 0 0 1 6.954 6.194 5.5 5.5 0 0 1-.953 10.784v-2.014a3.5 3.5 0 1 0-1.112-6.91 5 5 0 1 0-9.777 0 3.5 3.5 0 0 0-1.292 6.88l.18.03v2.014a5.5 5.5 0 0 1-.954-10.784A7 7 0 0 1 12 2z"
                        ></path>
                      </svg>
                      <span class="text-sm opacity-50">Carregar ficheiros</span
                      ><span class="text-xs opacity-50">ou arraste para esta zona</span>
                    </div>

                    <div v-else-if="input.type === 'multiple_choice'" class="fw-multiplechoice flex flex-col gap-2">
                      <div
                        v-for="(option, o) in input.options"
                        :key="'option_' + o"
                        class="multiplechoice_option_editor py-1 px-2 relative border border-gray-100 rounded-md text-base font-semibold text-gray-800 bg-white w-full text-left flex justify-start items-center group gap-4"
                        :class="{
                          editable: editable,
                          //'cursor-pointer': editing_option !== input.key + '_' + option.key && editable,
                          //' ': editing_option !== input.key + '_' + option.key,
                          active: editing_option === input.key + '_' + option.key && editable,
                          'py-0.5 pr-1': editing_option === input.key + '_' + option.key,
                          selected_wrong:
                            user_answers &&
                            user_answers.answers &&
                            user_answers.answers[page.key] &&
                            user_answers.answers[page.key][input.key] &&
                            user_answers.answers[page.key][input.key].includes(option.key) &&
                            !(
                              pages[p].scores &&
                              pages[p].scores[input.key] &&
                              pages[p].scores[input.key].options &&
                              pages[p].scores[input.key].options.includes(option.key)
                            ),
                          selected_correct:
                            user_answers &&
                            user_answers.answers &&
                            user_answers.answers[page.key] &&
                            user_answers.answers[page.key][input.key] &&
                            user_answers.answers[page.key][input.key].includes(option.key) &&
                            !!(
                              pages[p].scores &&
                              pages[p].scores[input.key] &&
                              pages[p].scores[input.key].options &&
                              pages[p].scores[input.key].options.includes(option.key)
                            ),
                          missed_selection:
                            user_answers &&
                            user_answers.answers &&
                            user_answers.answers[page.key] &&
                            user_answers.answers[page.key][input.key] &&
                            !user_answers.answers[page.key][input.key].includes(option.key) &&
                            !!(
                              pages[p].scores &&
                              pages[p].scores[input.key] &&
                              pages[p].scores[input.key].options &&
                              pages[p].scores[input.key].options.includes(option.key)
                            )
                        }"
                      >
                        <div
                          class="multiplechoice_letter text-center py-2 bg-gray-200 uppercase rounded-xl leading-4 text-lg font-medium"
                          style="width: 2rem"
                        >
                          {{ String.fromCharCode(97 + o) }}
                        </div>
                        <div class="flex-1 flex gap-2 items-center">
                          <input
                            v-if="editable"
                            :ref="input.key + '_' + option.key"
                            v-model="option.text[language]"
                            placeholder="Texto da opção"
                            class="flex-1 w-full text-base font-semibold"
                            type="text"
                            @input="dataChanged(p)"
                          />
                          <div v-else class="flex-1 w-full text-base font-semibold">
                            {{ option.text[language] ? option.text[language] : 'Sem texto' }}
                          </div>
                          <fw-button
                            v-if="(editable || (canUpdateQuestions && user_answers == null)) && input.ignored !== true"
                            type="dropdown"
                            :check-box="true"
                            :checked="
                              !!(
                                pages[p].scores &&
                                pages[p].scores[input.key] &&
                                pages[p].scores[input.key].options &&
                                pages[p].scores[input.key].options.includes(option.key)
                              )
                            "
                            :custom-class="'py-1'"
                            @click.native="setCorrectOption(p, s, i, o)"
                          >
                            Opção certa
                          </fw-button>
                          <div
                            v-else-if="
                              !editable &&
                                !!(
                                  pages[p].scores &&
                                  pages[p].scores[input.key] &&
                                  pages[p].scores[input.key].options &&
                                  pages[p].scores[input.key].options.includes(option.key)
                                )
                            "
                            class="text-primary flex gap-1 text-sm items-center px-1"
                          >
                            <fw-icon-check class="text-primary h-5 w-5"></fw-icon-check>
                            Opção certa
                          </div>

                          <b-dropdown
                            v-if="input.options.length > 2 && editable"
                            :scrollable="true"
                            :max-height="200"
                            aria-role="list"
                            position="is-bottom-left"
                          >
                            <template #trigger="{ active }">
                              <fw-button type="transparent" size="sm" class="text-gray-400">
                                <fw-icon-more class="h-5 w-5" />
                              </fw-button>
                            </template>

                            <b-dropdown-item aria-role="listitem">
                              <div
                                class="flex justify-start items-center gap-3 font-semibold text-gray-600"
                                @click="deleteMultiplechoiceOption(p, s, i, o, false)"
                              >
                                Eliminar opção
                              </div>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                        <div
                          v-if="false"
                          class="flex-1 flex items-center gap-2"
                          @click="editMultiplechoiceOption(input.key + '_' + option.key)"
                        >
                          <div class="flex-1">
                            {{ option.text[language] }}
                          </div>

                          <div
                            v-if="editable"
                            class="inline-block relative ml-2 flex items-center gap-1 group-hover:bg-opacity-0 opacity-30 group-hover:opacity-70 text-sm bg-gray-200 bg-opacity-80 text-gray-500 font-bold flex items-center justify-center text-sm rounded focus:outline-none"
                            style="height: 20px; width: 20px; padding: 2px"
                          >
                            <svg
                              class="fill-current"
                              style="height: 16px; width: 16px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z"
                              />
                            </svg>
                          </div>
                          <fw-icon-checkbox-solid
                            v-if="
                              !!(
                                pages[p].scores &&
                                pages[p].scores[input.key] &&
                                pages[p].scores[input.key].options &&
                                pages[p].scores[input.key].options.includes(option.key)
                              )
                            "
                            class="w-5 h-5 bg-white rounded-full text-primary flex-shrink-0"
                          />
                        </div>
                      </div>
                      <button
                        v-if="input.options.length < 26 && editable"
                        class="flex bg-gray-50 border border-gray-100 hover:border-primary hover:bg-white items-center justify-center group cursor-pointer text-center rounded-md text-gray-400 font-semibold px-2 py-2 hover:text-gray-700"
                        @click="addNewMultiplechoiceOption(p, s, i)"
                      >
                        <div
                          class="multiplechoice_letter text-center flex flex-col justify-center items-center bg-gray-200 uppercase rounded-xl leading-4 text-lg font-medium"
                          style="width: 2rem; height: 2rem"
                        >
                          <svg
                            class="h-5 w-5 text-gray-500 group-hover:text-primary fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z" />
                          </svg>
                        </div>
                        <div class="flex-1 text-left pl-5">Adicionar nova opção</div>
                      </button>
                    </div>

                    <div
                      v-else-if="input.type === 'image_direct'"
                      class="overflow-hidden rounded-2xl mt-2.5 bg-gray-200 image_direct"
                    >
                      <img :src="input.directUrl" class="w-full" />
                    </div>

                    <input
                      v-if="input.type === 'image_direct' && input.message && input.message[language]"
                      v-model="input.message[language]"
                      class="text-sm outline-none w-full text-gray-400 italic bg-transparent mt-1"
                      :placeholder="'Legenda da imagem'"
                    />

                    <div
                      v-if="
                        ((input.type === 'upload_file' || input.type === 'text_area') &&
                          user_answers != null &&
                          user_corrections != null &&
                          user_corrections[page.key] &&
                          user_corrections[page.key][input.key] &&
                          user_corrections[page.key][input.key].comment &&
                          user_corrections[page.key][input.key].comment.length > 0) ||
                          commenting == page.key + '_' + input.key
                      "
                      class="border border-gray-100 bg-gray-50 rounded p-3 my-2"
                    >
                      <div class="flex gap-2 mb-3 items-center">
                        <div class="text-gray-600 text-sm flex-1">Comentários da correção</div>
                        <fw-button
                          v-if="commenting == page.key + '_' + input.key"
                          type="light"
                          @click.native="cancelComment()"
                          >Cancelar</fw-button
                        >
                        <fw-button
                          v-if="commenting == page.key + '_' + input.key"
                          type="primary"
                          @click.native="saveEvaluation(true)"
                          >Guardar</fw-button
                        >
                        <fw-button
                          v-if="commenting != page.key + '_' + input.key"
                          type="primary"
                          @click.native="editComment(page.key, input.key)"
                          >Editar</fw-button
                        >
                      </div>
                      <b-input
                        v-if="commenting == page.key + '_' + input.key"
                        v-model="tmp_comment"
                        maxlength="200"
                        type="textarea"
                      ></b-input>
                      <div v-else class="text-sm">
                        {{ user_corrections[page.key][input.key].comment }}
                      </div>
                    </div>
                    <fw-button
                      v-if="
                        (input.type === 'upload_file' || input.type === 'text_area') &&
                          user_answers != null &&
                          user_corrections[page.key][input.key].comment.length == 0 &&
                          commenting != page.key + '_' + input.key &&
                          canCorrectExam &&
                          input.ignored !== true
                      "
                      class="mt-2"
                      type="light"
                      @click.native="editComment(page.key, input.key)"
                      >Adicionar comentários à correção</fw-button
                    >
                    <div v-if="false && editable">
                      <div class="pt-5 text-gray-400 text-sm">Definições da pergunta</div>
                      <div class="flex gap-5">
                        <div>
                          <fw-label>Pontuação</fw-label><br />
                          <input
                            v-model="input.score"
                            :disabled="!editable"
                            class="points_input question-points-2"
                            type="number"
                            inputmode="numeric"
                            @input="dataChanged(p)"
                          />
                        </div>
                        <div v-if="false && input.max > 1">
                          <fw-label>Número de opções certas para ter a pontuação máxima</fw-label><br />
                          <div class="flex text-xl items-center gap-2 mt-0.5">
                            <fw-button type="light" @click.native="changeAutoCorrection(p, s, i, false)"> - </fw-button>
                            <div class="w-7 text-center">
                              {{ input.min }}
                            </div>
                            <fw-button type="light" @click.native="changeAutoCorrection(p, s, i, true)"> + </fw-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="editable && totalQuestions < MAX_QUESTIONS"
                  class="pt-5 absolute left-5 -bottom-7"
                  style="z-index: 1"
                >
                  <button
                    class="flex items-center gap-2 text-gray-400 text-sm font-semibold hover:text-gray-500"
                    @click="addContent(p, s, i)"
                  >
                    <div
                      class="border-2 border-gray-300 text-semibold rounded-sm h-5 w-5 text-center"
                      style="line-height: 0.8rem"
                    >
                      +
                    </div>
                    Adicionar pergunta
                  </button>
                </div>
              </Draggable>
            </Container>
          </div>
          <div
            v-if="editable"
            class="section-toolbar flex gap-4 text-sm justify-end absolute -bottom-10 sm:-bottom-3 right-5"
          >
            <button
              v-if="false && typeof page.schema[s + 1] === 'undefined'"
              class="flex items-center gap-2 text-gray-400 font-semibold hover:text-gray-500"
              @click="addPage(p)"
            >
              <div
                class="border-2 border-gray-300 text-semibold rounded-sm h-5 w-4 text-center"
                style="line-height: 0.8rem"
              >
                +
              </div>
              Adicionar página
            </button>

            <button
              v-if="totalQuestions < MAX_QUESTIONS"
              class="flex gap-2 items-center text-gray-400 font-semibold hover:text-gray-500"
              @click="addSection(p, s)"
            >
              <svg
                class="fill-current"
                style="width: 1.4rem; height: 1.4rem"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M17 21v-4H7v4H5v-5a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v5h-2zM7 3v4h10V3h2v5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V3h2zM2 9l4 3-4 3V9zm20 0v6l-4-3 4-3z"
                />
              </svg>
              Adicionar secção
            </button>
          </div>
        </div>
      </div>
    </div>

    <fw-modal :active.sync="isModalActive" :can-cancel="currentModal === 'addContent'" size="min" @close="closeModal()">
      <ModalImportQuestionsRandom
        v-if="currentModal === 'importRandomQuestions'"
        :ignore-form="formid"
        @import-questions="importQuestion($event)"
        @close="closeModal()"
      ></ModalImportQuestionsRandom>

      <fw-panel v-else-if="currentModal === 'addContent'" :title="'Adicionar nova pergunta'" class="flex flex-col">
        <div class="pb-4 text-sm">Escolha o tipo de pergunta ou conteúdo a adicionar:</div>
        <div
          v-for="(menu, index) in menu_add_question"
          :key="index"
          class="flex justify-start items-center gap-3 p-2 rounded hover:bg-gray-100 cursor-pointer"
          @click="addInput(menu.type, null, null, null)"
        >
          <div v-html="menu.icon"></div>
          <div>
            <div class="font-bold">{{ menu.text[language] }}</div>
            <div class="text-gray-600">{{ menu.description[language] }}</div>
          </div>
        </div>
      </fw-panel>
      <fw-panel v-else-if="currentModal === 'ignoreQuestion'" :title="'Anular pergunta'" class="flex flex-col gap-2">
        <div class="pb-4 text-sm">Que critério deveremos adoptar para esta pergunta?</div>
        <div class="flex flex-col gap-2">
          <b-field>
            <b-radio v-model="ignoreQuestionValue" native-value="give_score">
              Dar a pontuação desta pergunta a todos.
            </b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="ignoreQuestionValue" native-value="no_score">
              Remover a pontuação desta pergunta a todos.
            </b-radio>
          </b-field>
        </div>
        <div class="text-sm mt-5">
          Nota: ao remover a pontuação, a pergunta será ignorada no calculo da pontuação final e o total de pontos
          ajustados à escala do exame.
        </div>
        <div class="flex pt-5 gap-3">
          <div class="flex-1"></div>
          <fw-button type="link-muted" @click.native="closeModal()"> Cancelar </fw-button>
          <fw-button :type="'primary'" @click.native="confirmIgnoreQuestion()"> Anular pergunta </fw-button>
        </div>
      </fw-panel>
    </fw-modal>
  </div>
</template>

<script>
import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import utils from '@/fw-modules/fw-core-vue/utilities/utils.js'
import FormattedText from '@/fw-modules/fw-core-vue/ui/components/form/FormattedText'
import { Container, Draggable } from 'vue-dndrop'
import Vue from 'vue'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import TextHeadingDual from '../text/TextHeadingDual.vue'
import FwMessage from '@/fw-modules/fw-core-vue/ui/components/text/FwMessage'
import ModalImportQuestionsRandom from '@/fw-modules/fw-core-vue/exams/components/modals/ModalImportQuestionsRandom'
import ProgressIcon from '@/fw-modules/fw-core-vue/ui/components/ProgressIcon'

export default {
  name: 'PanelExamEditor',
  components: {
    Container,
    Draggable,
    FormattedText,
    Person,
    Uploader,
    RecordFileEntry,
    TextHeadingDual,
    FwMessage,
    ModalImportQuestionsRandom,
    ProgressIcon
  },
  props: {
    showBackButton: {
      type: Boolean,
      default: true
    },
    originalInstance: {
      type: Object,
      default: null,
      required: true
    },
    permissions: {
      type: Object,
      default: () => {
        return {}
      }
    },
    answersid: {
      type: String,
      default: null
      //return this.$route.params.answerid ? this.$route.params.answerid : null
    }
  },
  data: () => {
    return {
      permissions_email: '',
      usersWithPermissions: [],
      currentModal: null,
      tmpPosition: null,
      publishing: false,
      loading: true,
      saving: false,
      errorSaving: false,
      errors: [],
      editing_option: '',
      editing_score: '',
      editing_score_value: 1,
      dirtyPages: new Set(),
      allPagesCompleted: false,
      showOptions: false,
      rightMargin: 200, //used in header animation (for the right toolbar)
      animateTitle: false,
      animateToolbar: false,
      isMobile: false,
      menu_add_question: [
        {
          icon:
            '<div class="w-12 h-12 pt-1 rounded-lg bg-white shadow flex flex-col gap-1">' +
            '<div class="rounded-l pl-0.5 leading-4 border border-gray-100 ml-2 flex items-center text-gray-300 border-r-transparent">A</div>' +
            '<div class="rounded-l pl-0.5 leading-4 border border-gray-100 ml-2 flex items-center text-gray-300 border-r-transparent">B</div>' +
            '</div>',
          text: {
            pt: 'Pergunta de escolha múltipla',
            en: 'Multiple choice question'
          },
          description: {
            pt: 'Permite escolher uma das opções definidas',
            en: 'Allow choose one of the options defined'
          },
          type: 'multiple_choice'
        },

        {
          type: 'text_area',
          icon:
            '<div class="w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1">' +
            '<div class="rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0">' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-l-lg h-1.5 w-full"></div>' +
            '<div class="bg-gray-100 rounded-lg h-1.5 w-6"></div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Pergunta de resposta aberta',
            en: 'Open answer question'
          },
          description: {
            pt: 'Permite responder em texto livre',
            en: 'Allow to answer in plain text'
          }
        },
        /*{
          type: 'image_direct',
          icon:
            "<div class='w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1.5 leading-4 border justify-center text-center border-gray-200 ml-4 text-gray-300 border-r-0 relative'>" +
            '<svg class="fill-current h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M11.27 12.216L15 6l8 15H2L9 8l2.27 4.216zm1.12 2.022L14.987 19h4.68l-4.77-8.942-2.507 4.18zM5.348 19h7.304L9 12.219 5.348 19zM5.5 8a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"/></svg>' +
            "<div class='rounded-full bg-white text-gray-300 border border-gray-200 absolute -bottom-2 -left-3'>" +
            '<svg class="fill-current h-4 w-4 my-0.5 mx-0.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07z"/></svg>' +
            '</div>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Imagem de link',
            en: 'Image from link',
          },
          description: {
            pt: 'Mostre uma imagem usando o seu url',
            en: 'Show an image using its url',
          },
        },*/

        {
          type: 'upload_file',
          icon:
            "<div class='w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 pl-1 leading-4 border border-gray-200 ml-2 text-gray-300 border-r-0'>" +
            "<svg class='fill-current h-5 w-5' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'>" +
            "<path fill='none' d='M0 0h24v24H0z' /> <path d='M1 14.5a6.496 6.496 0 0 1 3.064-5.519 8.001 8.001 0 0 1 15.872 0 6.5 6.5 0 0 1-2.936 12L7 21c-3.356-.274-6-3.078-6-6.5zm15.848 4.487a4.5 4.5 0 0 0 2.03-8.309l-.807-.503-.12-.942a6.001 6.001 0 0 0-11.903 0l-.12.942-.805.503a4.5 4.5 0 0 0 2.029 8.309l.173.013h9.35l.173-.013zM13 13v4h-2v-4H8l4-5 4 5h-3z'  />" +
            '</svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Zona para envio de ficheiros',
            en: 'Place to upload files'
          },
          description: {
            pt: 'Permitir anexar ficheiros',
            en: 'Allow to attach files'
          }
        },
        {
          type: 'random_questions',
          icon:
            "<div class='w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 text-center leading-4 text-gray-300 border-r-0'>" +
            '<svg class="fill-current h-7 w-7 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M252.3 11.7c-15.6-15.6-40.9-15.6-56.6 0l-184 184c-15.6 15.6-15.6 40.9 0 56.6l184 184c15.6 15.6 40.9 15.6 56.6 0l184-184c15.6-15.6 15.6-40.9 0-56.6l-184-184zM248 224c0 13.3-10.7 24-24 24s-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24zM96 248c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24zm128 80c13.3 0 24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24s10.7-24 24-24zm128-80c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24zM224 72c13.3 0 24 10.7 24 24s-10.7 24-24 24s-24-10.7-24-24s10.7-24 24-24zm96 392c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V240c0-26.5-21.5-48-48-48H472.5c13.4 26.9 8.8 60.5-13.6 82.9L320 413.8V464zm160-88c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/></svg>' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Importar perguntas aleatórias',
            en: 'Import random questions'
          },
          description: {
            pt: 'Inserir uma ou várias perguntas de uma outra avaliação',
            en: 'Insert one or more random questions from other evaluation'
          }
        }
        /*{
          type: 'text_content',
          icon:
            "<div class='w-12 h-12 rounded-lg bg-white shadow flex flex-col justify-center gap-1'>" +
            "<div class='rounded-l flex flex-col py-1 gap-1 leading-4 text-center text-gray-300 border-r-0 font-bold text-2xl'>" +
            'Aa' +
            '</div>' +
            '</div>',
          text: {
            pt: 'Conteúdo de texto',
            en: 'Text content',
          },
          description: {
            pt: 'Mostre texto adicional',
            en: 'Show additional text',
          },
        },*/
      ],
      dropPlaceholderOptions: {
        className: 'drop-preview',
        animationDuration: '150',
        showOnTop: true
      },
      cover: null,
      startAt: null,
      endsAt: null,
      pages: [], //por página, secção, campo
      scores: null,
      editors: {},
      scrollY: 0,
      language: 'pt', //currently editing
      total_score: 0,
      form: null,
      user_answers: null,
      user_corrections: null,
      commenting: null,
      tmp_comment: '', //used to store temporary data of the comment (and be able to restore the original comment)
      override_formid: null, //used for user answers
      user: null,
      debouce_timer: null,
      instance: null,
      currentTotalPoints: 0,
      missingPoints: false,
      tmpSelection: null,
      ignoreQuestionValue: 'give_score',
      showSaveCorrection: false,
      totalQuestions: 0,
      MAX_QUESTIONS: 100
    }
  },
  computed: {
    instanceEnded() {
      return this.originalInstance && this.originalInstance.status == 'ended'
    },
    totalExamPoints() {
      return this.form !== null ? this.form.options.scale : 0
    },
    pointsProgress() {
      return (this.currentTotalPoints / this.totalExamPoints) * 100
    },
    missingOptions() {
      //NOTE: this only uses the first page!!! -> If we have more than one page, we need to change this
      for (let index = 0; index < this.pages[0].schema.length; index++) {
        const schema = this.pages[0].schema[index]
        for (let index = 0; index < schema.content.length; index++) {
          const question = schema.content[index]
          if (
            question.type === 'multiple_choice' &&
            (this.pages[0].scores[question.key] === undefined || this.pages[0].scores[question.key].length === 0)
          ) {
            return true
          }
        }
      }
      return false
    },

    isModalActive() {
      return this.currentModal !== null
    },
    currentVersionText() {
      let version = this.originalInstance.forms.find(v => v.key === this.formid)
      return version ? 'Versão ' + version.letter : '--' //+ String.fromCharCode(64 + this.formIndex)
    },
    myStorageToken() {
      return this.$store.getters.getUser.token
    },
    ServiceStorage() {
      return ServiceStorage
    },
    isPermissionEmailValid() {
      return utils.isValidEmail(this.permissions_email)
    },
    canCorrectExam() {
      return this.permissions.canWriteAnswersComments
    },
    examLink() {
      return process.env.VUE_APP_IS_PRODUCTION
        ? `https://ucstudent.uc.pt/ex/${this.instance.key}`
        : `https://ucstudent.dev.ucframework.pt/ex/${this.instance.key}`
    },
    editable() {
      return this.form && this.form.permissions.edit ? this.form.permissions.edit : false
    },
    currentVersion() {
      let subid = this.subid
      return this.instance !== null && this.instance.forms.length > 1
        ? this.instance.forms.findIndex(item => item.key === subid)
        : -1
    },
    dirtyData() {
      return this.$store.state.dirtyData
    },
    savingData() {
      return this.saving
    },
    canStart() {
      //TODO: if minimal conditions are meet the user can publich the form
      return this.instance !== null && this.permissions.start === true
    },
    canIgnoreQuestions() {
      //TODO: Mudar quando o Tomás implementar a funcionalidade
      return this.permissions.canIgnoreQuestions
    },
    canUpdateQuestions() {
      return this.permissions.canUpdateOptions
    },
    canPublish() {
      //TODO: if minimal conditions are meet the user can publich the form
      return (
        this.allPagesCompleted &&
        ((this.instance === null && this.form.permissions && this.form.permissions.close === true) ||
          (this.instance !== null && this.permissions.publish === true))
      )
    },
    type() {
      return this.$route.params.type ? this.$route.params.type : null
    },
    id() {
      return this.$route.params.id ? this.$route.params.id : null
    },
    subid() {
      return this.$route.params.versionid ? this.$route.params.versionid : null
    },
    formid() {
      //builder/exam/FORM_ID
      //OR
      //builder/exam/INSTANCE_ID/FORM_ID
      //OR
      //builder/exam/INSTANCE_ID/FORM_ID
      //this.subid //this.answersid === null ? (this.subid !== null ? this.subid : this.id) : this.override_formid
      return this.answersid === null ? this.subid : this.override_formid
    },
    instanceid() {
      //builder/exam/INSTANCE_ID/FORM_ID
      return this.id
    },
    examVersions() {
      if (this.instance == null) return []
      if (Array.isArray(this.instance.forms)) {
        return this.instance.forms.map(form => form.key)
      } else {
        return Object.keys(this.instance.forms)
      }
    }
  },
  watch: {
    $route(to, from) {
      if (from.fullPath.indexOf('/new') !== -1) {
        console.log('loadFormData')
        //to consider version change
        this.loadFormData()
      }
    }
  },
  async created() {
    await this.loadFormData()
  },
  /*mounted() {
    let self = this
    document.querySelector('main').addEventListener('scroll', function() {
      let top = document.querySelector('main').scrollTop
      if (top > 88 && self.animateTitle === false) {
        self.animateTitle = true
        Vue.nextTick(function() {
          self.animateToolbar = true
        })
      } else if (top <= 88 && self.animateTitle === true) {
        self.animateToolbar = false
        Vue.nextTick(function() {
          self.animateTitle = false
        })
      }
    })

    window.addEventListener('resize', function() {
      if (!this.loading) {
        self.calculateRightMargin()
      }
    })
  },
  beforeDestroy() {
    let self = this
    window.removeEventListener('resize', function() {
      self.calculateRightMargin()
    })
    document.querySelector('main').removeEventListener('scroll', function() {
      let top = document.querySelector('main').scrollTop
      if (top > 88 && self.animateTitle === false) {
        self.animateTitle = true
        Vue.nextTick(function() {
          self.animateToolbar = true
        })
      } else if (top <= 88 && self.animateTitle === true) {
        self.animateTitle = false
        Vue.nextTick(function() {
          self.animateToolbar = false
        })
      }
    })
  },*/
  methods: {
    addContent(p, s, i) {
      if (this.totalQuestions < this.MAX_QUESTIONS) {
        this.tmpPosition = {
          page: p,
          section: s,
          index: i
        }
        this.currentModal = 'addContent'
      }
    },
    async importQuestion(importedQuestions) {
      let questions = importedQuestions.questions
      let uniqueQuestions = []
      let answers = importedQuestions.answers
      let files = []
      //TODO: replace question IDS with newly generated ones
      console.log('importing questions', this.tmpPosition, questions)
      let repeatedQuestions = []
      let resultDuplicateFiles = {}
      if (this.tmpPosition !== null) {
        //be sure to only import the answers that are in the form
        questions.forEach(quest => {
          //verify if we already have this question
          let existingQuestion = this.pages[this.tmpPosition['page']]['schema'][this.tmpPosition['section']][
            'content'
          ].find(q => q.key === quest.key)
          if (existingQuestion) {
            repeatedQuestions.push(quest.key)
          } else {
            //check files
            if (quest['file'] && quest['file']['key']) {
              files.push(quest['file']['key'])
            }
            if (answers[quest.key]) {
              this.pages[this.tmpPosition['page']].scores[quest.key] = answers[quest.key]
            }
            //add points to each questions
            quest['score'] = this.form.options.pointsCorrectInput ?? 1
            uniqueQuestions.push(quest)
          }
        })

        //DUPLICATE FILES
        if (files.length > 0) {
          resultDuplicateFiles = await FormServices.duplicateFiles(files)
          console.log('DUPLICATE FILES RESULT ', resultDuplicateFiles)
          uniqueQuestions = []
        }

        questions.forEach(quest => {
          //verify if we already have this question
          let existingQuestion = this.pages[this.tmpPosition['page']]['schema'][this.tmpPosition['section']][
            'content'
          ].find(q => q.key === quest.key)
          if (!existingQuestion) {
            //check files
            if (quest['file'] && quest['file']['key'] && resultDuplicateFiles[quest['file']['key']]) {
              //replace file for new one
              quest['file'] = resultDuplicateFiles[quest['file']['key']]
            }
            uniqueQuestions.push(quest)
          }
        })
        let p = this.tmpPosition['page']
        let previousContent = this.pages[p]['schema'][this.tmpPosition['section']]['content']
        if (this.tmpPosition['index'] === null) {
          this.pages[p]['schema'][this.tmpPosition['section']]['content'] = previousContent.concat(uniqueQuestions)
        } else if (uniqueQuestions.length > 0) {
          let pos = this.tmpPosition['index'] + 1
          let result = previousContent.slice(0)
          let secondPart = previousContent.slice(pos, previousContent.length)
          result = result.concat(uniqueQuestions)
          result = result.concat(secondPart)
          this.pages[p]['schema'][this.tmpPosition['section']]['content'] = result
        }

        console.log(
          'IMPORTATION SUMMARY: ',
          uniqueQuestions.length,
          ' imported; ',
          repeatedQuestions.length,
          ' repeated'
        )
        console.log('FICHEIROS ', files)
        this.dataChanged(this.tmpPosition['page'])
        this.closeModal()
        this.tmpPosition = null
        this.calculateTotalPoints()
        if (repeatedQuestions.length > 0) {
          this.$buefy.dialog.alert({
            message: `Este exame já continha ${repeatedQuestions.length} das perguntas importadas, pelo que foram ignoradas.`,
            type: 'is-danger',
            hasIcon: false,
            icon: 'times-circle',
            iconPack: 'fa',
            ariaRole: 'alertdialog',
            ariaModal: true
          })
        }
      }
    },
    closeModal() {
      console.log('closeModal')
      this.currentModal = null
    },
    cancelComment() {
      this.tmp_comment = ''
      this.commenting = null
    },
    async saveEvaluation(saveTemporaryComment) {
      if (!this.saving) {
        if (saveTemporaryComment) {
          let commentIds = this.commenting.split('_')
          this.user_corrections[commentIds[0]][commentIds[1]].comment = this.tmp_comment
          this.tmp_comment = ''
          this.commenting = null
        }
        this.errorSaving = false
        this.saving = true
        let correction = this.user_corrections
        console.log('correction', correction)
        try {
          let result = await FormServices.saveManualCorrection(this.originalInstance.key, this.answersid, correction)
          console.log(result)
          this.$buefy.snackbar.open({
            message: `Alterações guardadas.`,
            position: 'is-top-right'
          })
        } catch (e) {
          console.error(e)
          this.errorSaving = true
        } finally {
          this.saving = false
        }
      }
    },
    editComment(page, input) {
      this.commenting = page + '_' + input
      this.tmp_comment = this.user_corrections[page][input].comment
    },
    downloadFile(file) {
      console.log('downloadFile', file)
      const userToken = file.token ? file.token : this.$store.state.session.user.token
      const fileurl = file.file ? file.file.url_format : file.url_format
      const filekey = file.file ? file.file.key : file.key
      const filename = file.file && file.file.filename ? file.file.filename : file.filename

      const url = fileurl
        .replaceAll('{TOKEN}', userToken)
        .replaceAll('{KEY}', filekey)
        .replaceAll('{FILENAME}', filename)

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute('download', file.filename)
      document.body.appendChild(link)
      link.click()
    },
    correctionChanged(page) {
      //TODO:
      console.log('correctionChanged', page)
    },
    confirmIgnoreQuestion() {
      let pos = this.tmpSelection
      let value = this.ignoreQuestionValue
      Vue.set(this.pages[pos.p].schema[pos.s].content[pos.i], 'ignored', true)
      Vue.set(this.pages[pos.p].schema[pos.s].content[pos.i], 'ignored_options', value) //'give_score' | no_score
      this.dataChanged(pos.p)
      this.closeModal()
    },
    ignoreQuestion(p, s, i) {
      this.tmpSelection = {
        p: p,
        s: s,
        i: i
      }
      this.currentModal = 'ignoreQuestion'
    },
    revertIgnoreQuestion(p, s, i) {
      this.$buefy.dialog.confirm({
        message: `Tem a certeza que pretende reverter a anulação desta pergunta?`,
        trapFocus: true,
        onConfirm: value => {
          console.log(value)
          Vue.set(this.pages[p].schema[s].content[i], 'ignored', false)
          Vue.set(this.pages[p].schema[s].content[i], 'ignored_options', null)
          this.dataChanged(p)
        }
      })
    },
    changeMaxChar(p, s, i) {
      let currentValue = this.pages[p].schema[s].content[i].maxChars
      this.$buefy.dialog.prompt({
        message: `Qual o comprimento máximo para a resposta a esta pergunta?`,
        inputAttrs: {
          type: 'number',
          placeholder: 'Insira o número máximo de caracteres',
          value: currentValue,
          min: 0
        },
        trapFocus: true,
        onConfirm: value => {
          console.log(value)
          Vue.set(this.pages[p].schema[s].content[i], 'maxChars', value)
          this.dataChanged(p)
        }
      })
    },
    changeAutoCorrection(p, s, i, more = false) {
      let max = this.pages[p].schema[s].content[i].max
      let currentMinValue = this.pages[p].schema[s].content[i].min
      if (more && currentMinValue < max) {
        currentMinValue = currentMinValue + 1
        this.pages[p].schema[s].content[i].min = currentMinValue
        this.dataChanged(p)
      } else if (!more && currentMinValue > 1) {
        currentMinValue = currentMinValue - 1
        this.pages[p].schema[s].content[i].min = currentMinValue
        this.dataChanged(p)
      }
    },
    uploadFileToQuestion(p, s, i, event) {
      console.log('UPLOAD RESULT')
      console.log(event)
      if (event.length > 0) {
        let serverFile = event[0].response.data.file
        Vue.set(this.pages[p].schema[s].content[i], 'file', serverFile)
        this.dataChanged(p)
      }
    },
    removeFile(p, s, i) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover a imagem?',
        type: 'is-danger',
        onConfirm: () => {
          Vue.set(this.pages[p].schema[s].content[i], 'file', null)
          this.dataChanged(p)
        }
      })
    },
    imageThumbUrl(file) {
      return file.thumb_url_format
        .replace('{KEY}', file.key)
        .replace('{SIZE}', 'max2k')
        .replace('{FILENAME}', file.thumb_filename || file.filename)
    },
    editInputScore(id) {
      if (this.editable) {
        this.editing_score = id
        this.editing_score_value = this.form.options.pointsCorrectInput
      }
    },
    saveInputScore(p, s, i) {
      Vue.set(this.pages[p].schema[s].content[i], 'score', this.editing_score_value)
      this.editing_score = ''
      this.dataChanged(p)
    },
    removeInputScore(p, s, i) {
      Vue.set(this.pages[p].schema[s].content[i], 'score', null)
      this.editing_score = ''
      this.dataChanged(p)
    },
    checkPagesCompleteness() {
      for (let i = 0; i < this.pages.length; i++) {
        if (this.pages[i].complete !== true) {
          this.allPagesCompleted = false
          return
        }
      }
      this.allPagesCompleted = true
    },
    goToSection(key) {
      document.getElementsByTagName('main')[0].scrollTo({
        top:
          this.$refs['section_' + key][0].getBoundingClientRect().top +
          document.getElementsByTagName('main')[0].scrollTop -
          55,
        behavior: 'smooth'
      })
    },
    renderNav() {
      let all = document.getElementsByClassName('empty_nav_bt')
      for (let i = 0; i < all.length; i++) {
        //if (all[i].classList.contains('empty_nav_bt')) {
        all[i].innerHTML = 'S' + (i + 1)
        //}
      }
    },
    async changeLanguage(language) {
      this.language = language
    },
    async formatPageData() {
      //when you change language just verify if the data inside pages is correct
      this.pages.forEach((page, p) => {
        if (page.title[this.language] === undefined) {
          page.title[this.language] = this.language === 'pt' ? 'Página ' + (p + 1) : 'Page ' + (p + 1)
        }
        page.schema.forEach(section => {
          if (section.title[this.language] === undefined) {
            section.title[this.language] = ''
          }
          if (section.description[this.language] === undefined) {
            section.description[this.language] = ''
          }
          section.content.forEach(input => {
            if (input.instructions[this.language] === undefined) {
              input.instructions[this.language] = ''
            }

            if (input.placeholder[this.language] === undefined) {
              input.placeholder[this.language] = ''
            }

            if (Array.isArray(input.options)) {
              input.options.forEach((option, o) => {
                if (option.text[this.language] === undefined) {
                  option.text[this.language] =
                    this.language == 'pt'
                      ? 'Opção ' + String.fromCharCode(65 + o)
                      : 'Option ' + String.fromCharCode(65 + o)
                }
              })
            }
          })
        })
      })
    },
    async loadFormData() {
      this.loading = true
      try {
        this.instance = this.originalInstance
        let corrections = {}
        let userAnswers = null
        if (this.answersid != null) {
          if (this.permissions && this.permissions.seeAnswers) {
            userAnswers = await FormServices.getExamineeAnswers(this.originalInstance.key, this.answersid)
            corrections = userAnswers.grades
            this.user_answers = userAnswers
            this.override_formid = userAnswers.formKey
          } else {
            this.showError('Não tem permissões para ver as respostas a este exame')
            return
          }
        }

        //Select first available language
        if (this.instance.languages.length > 0) {
          this.language = this.instance.languages[0]
        } else {
          this.language = 'pt'
        }

        let response = await FormServices.getForm(this.formid)

        //verify if we have current language
        if (!response.title || typeof response.title[this.language] === 'undefined') {
          response.title[this.language] = ''
        }

        if (response.options.pointsCorrectOption === null) {
          response.options.pointsCorrectOption = 0
        }

        if (response.options.pointsWrongOption === null) {
          response.options.pointsWrongOption = 0
        }

        this.form = response
        let promises = []
        //response.pages.forEach(page => promises.push(FormServices.getPage(page.key)))
        response.pages.forEach(page => promises.push(this.loadFormPage(page.key)))

        this.pages = []
        Promise.all(promises)
          .then(async result => {
            result.forEach(page => {
              //Build correction object

              /**
               * SCRIPT PARA ELIMINAR SCORES NÃO USADAS...
               */
              if (page.scores) {
                let schema = page.schema
                let validInputs = []
                for (let s = 0; s < schema.length; s++) {
                  for (let i = 0; i < page.schema[s].content.length; i++) {
                    //loop each input
                    validInputs.push(page.schema[s].content[i].key)

                    if (userAnswers != null && corrections[page.key] == null) {
                      corrections[page.key] = {}
                    }

                    if (
                      (page.schema[s].content[i].type == 'text_area' ||
                        page.schema[s].content[i].type == 'upload_file') &&
                      userAnswers != null
                    ) {
                      this.showSaveCorrection = true
                      if (
                        corrections[page.key][page.schema[s].content[i].key] == null ||
                        typeof corrections[page.key][page.schema[s].content[i].key] == 'undefined'
                      ) {
                        corrections[page.key][page.schema[s].content[i].key] = {
                          points: 0,
                          comment: ''
                        }
                      } else if (corrections[page.key][page.schema[s].content[i].key].comment == null) {
                        corrections[page.key][page.schema[s].content[i].key].comment = ''
                      }
                    }
                  }
                  //verify if we have current language
                  /*if (typeof page.title[this.language] === 'undefined') {
                  page.title[this.language] = ''
                }*/
                }
                console.log('validInputsIDS', validInputs)
                for (const inputid in page.scores) {
                  //console.log('Testing: ' + inputid)
                  if (!validInputs.includes(inputid)) {
                    console.log('DELETE SCORES: ' + inputid)
                    //TODO:
                    delete page.scores[inputid]
                  }
                }
              }
            })

            //define correction object
            this.user_corrections = corrections

            console.log('pagesLoaded', result)
            this.pages = result
            this.loading = false
            let self = this
            Vue.nextTick(() => {
              self.checkPagesCompleteness()
              self.calculateTotalPoints()
              /*setTimeout(() => {
                self.calculateRightMargin()
                //render sections
                self.renderNav()
              }, 500)*/
            })
          })
          .catch(error => {
            console.error(error)
            this.showError('Ocorreu um erro ao carregar o documento. Tente de novo mais tarde ou contacte o suporte.')
          })
      } catch (e) {
        console.error(e)
        this.showError('Ocorreu um erro ao carregar o documento. Tente de novo mais tarde ou contacte o suporte.')
      }
    },
    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },
    async loadFormPage(pageKey) {
      console.log('loadFormPage', pageKey)
      let pageResponse = await FormServices.getPage(pageKey)
      if (typeof pageResponse.title[this.language] === 'undefined') {
        pageResponse.title[this.language] = ''
      }

      for (let s = 0; s < pageResponse.schema.length; s++) {
        if (typeof pageResponse.schema[s].title[this.language] === 'undefined') {
          pageResponse.schema[s].title[this.language] = ''
        }

        for (let i = 0; i < pageResponse.schema[s].content.length; i++) {
          //score

          if (
            typeof pageResponse.schema[s].content[i].score === 'undefined' ||
            pageResponse.schema[s].content[i].score === null
          ) {
            pageResponse.schema[s].content[i].score = this.form.options.pointsCorrectInput ?? 1
          }
          //instructions
          if (
            typeof pageResponse.schema[s].content[i].instructions === 'undefined' ||
            typeof pageResponse.schema[s].content[i].instructions[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].instructions === 'undefined') {
              pageResponse.schema[s].content[i].instructions = {}
            }
            pageResponse.schema[s].content[i].instructions[this.language] = ''
          }
          //label
          if (
            typeof pageResponse.schema[s].content[i].label === 'undefined' ||
            typeof pageResponse.schema[s].content[i].label[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].label === 'undefined') {
              pageResponse.schema[s].content[i].label = {}
            }
            pageResponse.schema[s].content[i].label[this.language] = ''
          }
          //message
          if (
            pageResponse.schema[s].content[i].message &&
            typeof pageResponse.schema[s].content[i].message[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].message === 'undefined') {
              pageResponse.schema[s].content[i].message = {}
            }
            pageResponse.schema[s].content[i].message[this.language] = ''
          }
          //text
          if (
            pageResponse.schema[s].content[i].text &&
            typeof pageResponse.schema[s].content[i].text[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].text === 'undefined') {
              pageResponse.schema[s].content[i].text = {}
            }
            pageResponse.schema[s].content[i].text[this.language] = ''
          }
          //placeholder
          if (
            typeof pageResponse.schema[s].content[i].placeholder === 'undefined' ||
            typeof pageResponse.schema[s].content[i].placeholder[this.language] === 'undefined'
          ) {
            if (typeof pageResponse.schema[s].content[i].placeholder === 'undefined') {
              pageResponse.schema[s].content[i].placeholder = {}
            }

            if (pageResponse.schema[s].content[i].type === 'text_area') {
              pageResponse.schema[s].content[i]['placeholder'][this.language] =
                this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
            }

            pageResponse.schema[s].content[i].placeholder[this.language] = ''
          }

          //check options
          if (pageResponse.schema[s].content[i].options && pageResponse.schema[s].content[i].options.length > 0) {
            for (let o = 0; o < pageResponse.schema[s].content[i].options.length; o++) {
              let optiontext = pageResponse.schema[s].content[i].options[o].text
              /*let optionscore = 0
              if (
                this.scores &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o] &&
                this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
              ) {
                optionscore = Number(
                  this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].correct[o].score
                )
              }*/

              //? this.scores[pageResponse.key + '_' + pageResponse.schema[s].content[i].key].score
              //: 0

              /*try {
                optiontext = JSON.parse(optiontext)
                console.log('é JSON', optiontext)
              } catch (e) {
                //no json detected
                console.log('NAO é JSON', optiontext)
              }*/

              if (typeof optiontext !== 'string' && typeof optiontext[this.language] === 'undefined') {
                optiontext[this.language] = ''
              }
              pageResponse.schema[s].content[i].options[o].text = optiontext
              //pageResponse.schema[s].content[i].options[o].score = optionscore
            }
          }
        }
      }

      return pageResponse
    },
    async changeVersion(v) {
      if (this.instance !== null) {
        let formkey = this.instance.forms[v].key
        this.$router.push({ path: `/builder/${this.type}/${this.id}/${formkey}` })
        this.loadFormData()
      }
    },
    /*
    async createNewForm() {
      let formData = defaultFormStructure
      formData['type'] = this.type
      let response = await FormServices.saveForm(formData)

      //verify if we have current language
      if (typeof response.title[this.language] === 'undefined') {
        response.title[this.language] = ''
      }

      let title = {}

      title[this.language] = this.language === 'pt' ? 'Página 1' : 'Page 1'

      //add page to form
      let responsePage = await FormServices.addFormPage(
        response.key,
        title,
        [
          {
            boxed: true,
            required: false,
            title: '',
            key: '1',
            content: [],
          },
        ],
        {},
        null
      )

      return {
        form: response,
        page: responsePage,
      }
    },*/
    async createCloneInstance() {
      if (this.instance !== null && this.instance.forms.length > 0) {
        let formKeys = this.instance.forms.map(form => {
          return form.key
        })
        let local_instance = await FormServices.createInstance(formKeys, this.form.title, this.form.options, null)
        this.instance = local_instance
        this.currentModal = 'share'
        this.showOptions = true
        this.$router.push({ path: `/builder/${this.type}/${local_instance.key}/${formKeys[0]}` })
      }
    },
    //Clone a form: create a new form with same data
    async cloneFormFromData() {
      let formData = {}
      formData['type'] = this.type
      formData['title'] = this.form.title
      formData['instructions'] = this.form.instructions
      formData['metadata'] = this.form.metadata
      formData['options'] = this.form.options
      try {
        let response = await FormServices.saveForm(formData)

        //verify if we have current language
        if (typeof response.title[this.language] === 'undefined') {
          response.title[this.language] = ''
        }

        let promises = []
        for (let p = 0; p < this.pages.length; p++) {
          let nextTo = null
          promises.push(
            FormServices.addFormPage(
              response.key,
              this.pages[p].title,
              this.pages[p].schema,
              this.pages[p].scores,
              nextTo
            )
          )
        }
        //let pages =
        await Promise.all(promises)
        window.open('/builder/exam/' + response.key)
      } catch (e) {
        console.error('There was an error cloning the form', e)
      }
      /*return {
        form: response,
        pages: pages,
      }*/
    },

    setCorrectOption(p, s, i, o) {
      let correctOptionID = this.pages[p].schema[s].content[i].options[o].key
      let inputId = this.pages[p].schema[s].content[i].key
      let question = this.pages[p].schema[s].content[i]
      let questionMax = question.max ?? 1
      let afterExamFinished = !this.editable && this.canUpdateQuestions
      console.log('secorrectoption', 'max: ', questionMax, 'afterExamFinished: ', afterExamFinished)

      if (this.pages[p].scores && this.pages[p].scores[inputId] && this.pages[p].scores[inputId].type === 'choices') {
        //opções já definidas
        let selectedOptions = this.pages[p].scores[inputId] && this.pages[p].scores[inputId].options
        if (Array.isArray(selectedOptions)) {
          let index = selectedOptions.indexOf(correctOptionID)
          if (index >= 0) {
            if (this.instanceEnded) {
              return
            }
            selectedOptions.splice(index, 1)
            //Vue.set(this.pages[p].scores[inputId], 'options', [])
            if (selectedOptions.length == 0) {
              Vue.delete(this.pages[p].scores, inputId)
            }
          } else {
            selectedOptions.push(correctOptionID)
            //verify if we are not selecting all options
            if (
              (selectedOptions.length > questionMax && afterExamFinished) ||
              selectedOptions.length === question.options.length
            ) {
              //Vue.delete(this.pages[p].scores, inputId)
              selectedOptions.shift()
            }
            Vue.set(this.pages[p].scores[inputId], 'options', selectedOptions)
          }
          if (!this.instanceEnded) {
            //Change min e max da pergunta
            question['min'] = selectedOptions.length > 0 ? selectedOptions.length : 1
            question['max'] = selectedOptions.length > 0 ? selectedOptions.length : 1
          }
          //save question
          Vue.set(this.pages[p].schema[s].content, i, question)

          /*if (selectedOptions.length === 0) {
            //Vue.delete(this.pages[p].scores, inputId)
          } else {
            //Vue.set(this.pages[p].scores[inputId], 'options', selectedOptions)
          }*/
        } else {
          Vue.set(this.pages[p].scores[inputId], 'options', [correctOptionID])
        }
      } else {
        let data = {
          type: 'choices',
          options: [correctOptionID]
        }
        if (typeof this.pages[p].scores === 'undefined') {
          let tmp = {}
          tmp[inputId] = data
          Vue.set(this.pages[p], 'scores', tmp)
        } else {
          Vue.set(this.pages[p].scores, inputId, data)
        }
      }
      /*let newscore = 0
      if (
        this.pages[p].schema[s].content[i].options[o].score <= 0 ||
        typeof this.pages[p].schema[s].content[i].options[o].score === 'undefined'
      ) {
        newscore = 10
      }
      //console.log('original', this.pages[p].schema[s].content[i].options[o].score, 'new', newscore)
      Vue.set(this.pages[p].schema[s].content[i].options[o], 'score', newscore)*/
      this.dataChanged(p)
      //this.saveScores()
    },
    clickBackground() {
      this.editing_option = ''
    },
    addNewMultiplechoiceOption(p, s, i) {
      let options = this.pages[p].schema[s].content[i].options

      //let letter = String.fromCharCode(97 + options.length).toUpperCase()
      //let input_score = this.pages[p].schema[s].content[i].score

      let newOptionData = {
        key: Date.now().toString(), //this.makeid(8),
        text: {}
      }
      newOptionData['text'][this.language] = '' //this.language === 'pt' ? 'Opção ' + letter : 'Option ' + letter
      if (this.language === 'pt' && this.originalInstance.languages.indexOf('en') >= 0) {
        newOptionData['text']['en'] = '' //'Option ' + letter
      } else if (this.language === 'en' && this.originalInstance.languages.indexOf('pt') >= 0) {
        newOptionData['text']['pt'] = '' //'Opção ' + letter
      }
      options.push(newOptionData)
      /*if (input_score > 0) {
        let partial_score = input_score / options.length
        //redistribuir score pelas opções
        options.map(opt => {
          opt['score'] = partial_score
          return opt
        })
      }*/
      Vue.set(this.pages[p].schema[s].content[i], 'options', options)
      setTimeout(() => {
        this.$refs[this.pages[p].schema[s].content[i].key + '_' + newOptionData.key][0].focus()
      }, 500)
      this.dataChanged(p)
    },
    deleteMultiplechoiceOption(p, s, i, o, force = false) {
      if (force) {
        //verify if the option is selected
        let inputId = this.pages[p].schema[s].content[i].key
        let selectedOptions = this.pages[p].scores[inputId] && this.pages[p].scores[inputId].options
        let correctOptionID = this.pages[p].schema[s].content[i].options[o].key
        if (Array.isArray(selectedOptions) && selectedOptions.indexOf(correctOptionID) >= 0) {
          selectedOptions.splice(selectedOptions.indexOf(correctOptionID), 1)
          //Vue.set(this.pages[p].scores[inputId], 'options', [])
          if (selectedOptions.length == 0) {
            Vue.delete(this.pages[p].scores, inputId)
          }
        }

        this.pages[p].schema[s].content[i].options.splice(o, 1)
        if (Array.isArray(selectedOptions)) {
          if (selectedOptions.length === this.pages[p].schema[s].content[i].options.length) {
            //Vue.delete(this.pages[p].scores, inputId)
            selectedOptions.shift()
          }
        }
        this.dataChanged(p)
      } else {
        this.$buefy.dialog.confirm({
          message: 'Tem a certeza que quer remover a opção?',
          type: 'is-danger',
          onConfirm: () => {
            this.deleteMultiplechoiceOption(p, s, i, o, true)
          }
        })
      }
    },

    editMultiplechoiceOption(val) {
      if (this.editable) {
        this.editing_option = val
        let self = this
        Vue.nextTick(() => {
          //console.log(val, self.$refs[val], self.$refs)
          self.$refs[val][0].focus()
        })
      }
    },
    async closeForm(formId) {
      let response = await FormServices.updateFormStatus(formId, 'close')
      console.log('closeForm', response)
    },
    async saveForm(ignoreStatus = true) {
      console.log('saveForm activated!')
      if (!this.saving) {
        this.errors = []
        this.saving = true
        console.log('saving')
        let promises = []
        //save form
        //save pages
        this.dirtyPages.forEach(p => {
          if (p === 'form') {
            //save form
            let formData = Object.assign({}, this.form)
            if (ignoreStatus) {
              delete formData['status']
            }
            promises.push(FormServices.saveForm(formData))
          } else {
            //save page
            let pageData = JSON.parse(JSON.stringify(this.pages[p])) // Object.assign({},)
            console.log('PRE pageData', pageData)
            console.log('pageData', pageData)
            promises.push(FormServices.updateFormPage(this.pages[p].key, pageData))
          }
        })

        //let checkForm = false
        //success
        return Promise.all(promises)
          .then(result => {
            console.log(result, 'saved!')

            //save completeness
            let i = 0
            this.dirtyPages.forEach(p => {
              console.log('dirtypage', p, i, result[i])
              if (result[i] && result[i]['__errors__']) {
                console.error('Error saving page', result[i]['__errors__'])
                if (Array.isArray(result[i]['__errors__'])) {
                  console.error('Array of errors')
                  for (let e = 0; e < result[i]['__errors__'].length; e++) {
                    this.errors.push('(' + result[i]['__errors__'][e].field + ') ' + result[i]['__errors__'][e].detail)
                  }
                }
              }
              if (p === 'form') {
                Vue.set(this.form, 'permissions', result[i]['permissions'])
              } else {
                if (result[i] && result[i]['complete'] === true) {
                  //Vue.set(this.pages[p], 'complete', true)
                  Vue.set(this.pages[p], 'complete', true)
                  //checkForm = true
                } else if (result[i] && result[i]['complete'] === false) {
                  Vue.set(this.pages[p], 'complete', false)
                }

                //check if there is an uploaded file in the questions
                for (let s = 0; s < result[i]['schema'].length; s++) {
                  const section = result[i]['schema'][s]
                  for (let c = 0; c < section['content'].length; c++) {
                    const content = section['content'][c]
                    if (content['file'] !== null) {
                      console.log('setting file', content['file'])
                      Vue.set(this.pages[p]['schema'][s]['content'][c], 'file', content['file'])
                    }
                  }
                }
              }
              i++
            })

            //reset dirty
            this.dirtyPages.clear()
            let self = this
            Vue.nextTick(() => {
              self.checkPagesCompleteness()
              self.$emit('saved')
            })

            this.saving = false
            this.$store.commit('setDirtyData', false)
          })
          .catch(e => {
            console.error(e)
            this.saving = false
            return false
          })
      }
    },
    dataChanged(page) {
      console.log('dataChanged ' + page + ' activated!')
      this.dirtyPages.add(page)
      this.$store.commit('setDirtyData', true)
      this.calculateTotalPoints()
      //debounce, save
      let self = this
      if (this.debouce_timer !== null) {
        clearTimeout(this.debouce_timer)
      }
      this.debouce_timer = setTimeout(() => {
        self.debouce_timer = null
        console.log('debounced ' + page + ' activated!')
        self.saveForm()
      }, 500)
    },
    closeOptionsModal() {
      this.showOptions = false
    },
    changeOptions(option) {
      console.log('changing', option)
      Vue.set(this.form.options, option, !this.form.options[option])
      //this.form.options[option] =
    },
    calculateRightMargin() {
      //console.log(this.$refs['form_main_body'])
      //console.log(window.innerWidth, this.$refs['form_main_body'].getBoundingClientRect().right)
      this.isMobile = window.innerWidth < 640

      if (this.animateTitle) {
        this.rightMargin = 15
      } else if (this.form !== null && !this.loading && this.$refs['form_main_body']) {
        this.rightMargin = window.innerWidth - this.$refs['form_main_body'].getBoundingClientRect().right
      }
    },
    getChildPayload(index, p, s) {
      //console.log('getChildPayload', p, s, index)
      return this.pages[p].schema[s].content[index]
    },
    toogleCover() {
      if (this.cover === null) {
        this.cover = true
      } else {
        this.cover = null
      }
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDropSection(p, s, dropResult) {
      if (this.editable) {
        let result = this.applyDrag(this.pages[p].schema[s].content, dropResult)
        console.log('applyDrag', result)
        Vue.set(this.pages[p].schema[s], 'content', result)
        this.dataChanged(p)
      }
    },
    onDropPage(dropResult) {
      console.log(dropResult)
      //TODO
    },
    makeid(length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    addInput(type, page, section, i = null) {
      let addItem = true
      let image_url

      if (type === 'image_direct') {
        image_url = window.prompt(
          'Indique o link directo da imagem (apenas https://):',
          'https://www.example.com/imagelink.jpeg'
        )
        if (image_url == null || image_url == '') {
          console.log('link da imagem vazio')
          addItem = false
        } else if (image_url.startsWith('https://')) {
          //link válido
        } else {
          addItem = false
        }
      } else if (type === 'random_questions') {
        this.currentModal = 'importRandomQuestions'
        return
      } else {
        this.closeModal()
      }

      page = this.tmpPosition.page
      section = this.tmpPosition.section
      i = this.tmpPosition.index + 1

      let inputData = {
        placeholder: {},
        //TODO: generate uid
        key: Date.now().toString(), //this.makeid(8),
        type: type,
        instructions: {},
        label: {},
        file: null,
        score: null,
        min: this.form.options.negativePoints && type === 'multiple_choice' ? 0 : 1,
        max: type === 'multiple_choice' ? 1 : type === 'text_area' ? 1000 : 250,
        required: false
      }

      inputData['placeholder'][this.language] = ''
      inputData['instructions'][this.language] = ''
      inputData['label'][this.language] = ''

      if (type === 'multiple_choice') {
        inputData['random'] = false
        inputData['options'] = [
          {
            key: Date.now().toString() + '1',
            text: {}
          },
          {
            key: Date.now().toString() + '2',
            text: {}
          }
        ]
        inputData['options'][0]['text'][this.language] = this.language === 'pt' ? 'Opção A' : 'Option A'
        inputData['options'][1]['text'][this.language] = this.language === 'pt' ? 'Opção B' : 'Option B'
        inputData['score'] = this.form.options.pointsCorrectInput ?? 1
        this.currentTotalPoints += Number.parseFloat(inputData['score'])
      } else if (type === 'text_area') {
        inputData['min'] = 0
        //inputData['message'] = { pt: '', en: '' }
        inputData['message'] = {}
        inputData['message'][this.language] = ''
        inputData['placeholder'] = {}
        inputData['placeholder'][this.language] =
          this.language === 'pt' ? 'Escreva aqui a sua resposta' : 'Write here your answer'
        inputData['maxChars'] = 0
        inputData['score'] = this.form.options.pointsCorrectInput ?? 1
        this.currentTotalPoints += Number.parseFloat(inputData['score'])
      } else if (type === 'upload_file') {
        inputData['allowed'] = 'any'
        inputData['maxFiles'] = 0
        inputData['score'] = this.form.options.pointsCorrectInput ?? 1
        this.currentTotalPoints += Number.parseFloat(inputData['score'])
      } else if (type === 'image_direct') {
        inputData = {
          type: type,
          key: Date.now().toString(),
          directUrl: image_url
        }
      }

      if (addItem) {
        console.log('ADDING', inputData)
        if (i === null) {
          this.pages[page]['schema'][section]['content'].push(inputData)
        } else {
          this.pages[page]['schema'][section]['content'].splice(i, 0, inputData)
        }
        this.dataChanged(page)
      }
    },
    calculateTotalPoints() {
      let totalQuestions = 0
      this.missingPoints = false
      // go through all pages and sections and calculate total points
      this.currentTotalPoints = 0
      this.pages.forEach(page => {
        page.schema.forEach(section => {
          section.content.forEach(input => {
            if (input.score) {
              const points = Number.parseFloat(input.score)
              this.currentTotalPoints += points
              if (points <= 0) {
                this.missingPoints = true
              }
            } else {
              this.missingPoints = true
            }
            totalQuestions++
          })
        })
      })
      this.totalQuestions = totalQuestions
    },
    removeInput(page, section, i) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover a pergunta?',
        type: 'is-danger',
        onConfirm: () => {
          let inputId = this.pages[page]['schema'][section]['content'][i].key
          this.currentTotalPoints -= Number.parseFloat(this.pages[page]['schema'][section]['content'][i]['score'])
          this.pages[page]['schema'][section]['content'].splice(i, 1)
          Vue.delete(this.pages[page].scores, inputId)
          this.dataChanged(page)
        }
      })
    },
    addSection(p, s) {
      let data = {
        key: Date.now().toString(),
        title: {},
        description: {},
        content: [],
        required: false,
        boxed: true
      }
      data.title[this.language] = '' // this.language === 'pt' ? 'Secção ' + s : 'Section ' + s
      data.description[this.language] = ''
      this.pages[p].schema.splice(s + 1, 0, data)
      this.dataChanged(p)
      //let self = this
      /*Vue.nextTick(function() {
        self.renderNav()
      })*/
    },
    removeSection(p, s) {
      this.$buefy.dialog.confirm({
        message: 'Tem a certeza que quer remover a secção e todas as suas perguntas?',
        type: 'is-danger',
        onConfirm: () => {
          //remove all scores from this section
          this.pages[p].schema[s].content.forEach(input => {
            Vue.delete(this.pages[p].scores, input.key)
          })
          this.pages[p].schema.splice(s, 1)
          if (this.pages[p].schema.length === 0) {
            this.removePage(p)
          }
          this.dataChanged(p)
          this.renderNav()
          this.calculateTotalPoints()
        }
      })
    },
    async addPage(p) {
      let data = {
        schema: [
          {
            key: Date.now().toString(),
            title: {},
            content: [],
            required: false,
            boxed: true
          }
        ],
        scores: {}
      }
      data.schema[0].title[this.language] = '' // this.language === 'pt' ? 'Secção 1' : 'Section 1'

      let title = {}
      title[this.language] = this.language === 'pt' ? 'Página ' + (p + 1) : 'Page ' + (p + 1)

      let response = await FormServices.addFormPage(this.formid, title, data.schema, data.scores, this.pages[p].key)
      console.log('addPage', response)
      response.schema[0].title[this.language] = ''
      this.pages.splice(p + 1, 0, response)
      let self = this
      Vue.nextTick(() => {
        self.renderNav()
      })
    },
    async removePage(p) {
      let response = await FormServices.deleteFormPage(this.pages[p].key)
      if (response === true) {
        this.pages.splice(p, 1)
      }

      this.calculateTotalPoints()

      this.renderNav()
    },
    goBack() {
      //this.$router.back()
      this.$router.push('/exam/' + this.instance.key + '/evaluation')
    },
    cancelEditor() {
      if (this.dirtyPages.size > 0) {
        this.$buefy.dialog.confirm({
          message: 'Tem a certeza que quer sair sem guardar as alterações?',
          onConfirm: () => {
            this.$emit('cancel')
          }
        })
      } else {
        this.$emit('cancel')
      }
    }
  }
}
</script>

<style>
.multiplechoice_option_editor.editable .multiplechoice_letter {
  @apply text-gray-500;
}
.multiplechoice_option_editor.editable:hover .multiplechoice_letter {
  color: rgba(3, 164, 121, 0.7);
}
.multiplechoice_option_editor.editable:focus .multiplechoice_letter {
  color: rgba(3, 164, 121, 0.7);
}
.multiplechoice_option_editor.editable:hover {
  border-color: rgba(3, 164, 121, 0.7);
}

.multiplechoice_option_editor.selected_correct .multiplechoice_letter {
  background-color: #03b78e;
  @apply text-gray-100;
}
.multiplechoice_option_editor.selected_correct {
  border-color: rgba(3, 164, 121, 0.7);
  background-color: #e3f1ed;
  @apply font-semibold;
}

.multiplechoice_option_editor.missed_selection .multiplechoice_letter {
  background-color: #d2b44fff;
  @apply text-gray-100;
}
.multiplechoice_option_editor.missed_selection {
  border-color: rgba(248, 215, 25, 0.7);
  background-color: #fffdef;
  @apply font-semibold;
}

.multiplechoice_option_editor.selected_wrong .multiplechoice_letter {
  background-color: #b72403;
  @apply text-gray-100;
}
.multiplechoice_option_editor.selected_wrong {
  border-color: rgba(164, 33, 3, 0.7);
  background-color: #ecded9;
  @apply font-semibold;
}

.bt_nav_lateral {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.image_direct {
  min-height: 50px;
}
.view-builder .modal input {
  @apply leading-4 bg-gray-100 py-0;
  min-height: auto;
}
.add-content-box .add-content-label {
  @apply transition-all whitespace-nowrap mx-1 text-sm font-semibold;
}

.add-content-box .add-content-arrow {
  @apply transition-all overflow-hidden whitespace-nowrap;
  max-width: 27px;
}
.add-content-box .add-content-arrow.open {
  max-width: 500px !important;
}

.add-content-arrow {
  @apply rounded px-1 py-0.5 bg-primary text-white text-opacity-80 flex items-center;
}

.dndrop-container.vertical > .dndrop-draggable-wrapper {
  overflow: visible !important;
  display: block;
}

.add-content-arrow:after {
  content: '';
  position: absolute;
  right: -0.5em;
  width: 1.2em;
  height: 1.2em;
  border-radius: 0 0 0.2em 0;
  margin-top: 0px;
  transform: rotate(-45deg);
  background: linear-gradient(-45deg, #30a898 50%, transparent 50%);
}
.page-section .section-toolbar {
  @apply opacity-0 transition-all;
}
.page-section:hover .section-toolbar {
  @apply opacity-100;
}

.page-separator {
  @apply relative mt-12 mb-8 bg-gray-200 bg-opacity-40;
  height: 2px;
  box-shadow: inset 0px 1px rgba(55, 55, 55, 0.07);
  border-radius: 1px;
}

.page-separator .title {
  @apply absolute bg-gray-100 font-semibold uppercase text-xs text-gray-400 text-opacity-60 text-center -top-1.5;
  left: 50%;
  margin-left: -40px;
  width: 80px;
}

.real-white {
  background-color: #fdfdfd;
}

.slighty-gray-color {
  background-color: #fcfcfc;
}
.real-shadow-1 {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-2 {
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-3 {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
}
.real-shadow-4 {
  box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 12px 17px 2px rgba(0, 0, 0, 0.14), 0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
}
.bt-scafold {
  @apply rounded-md border font-bold border-gray-100 py-2.5 text-gray-700 text-center mx-auto cursor-pointer text-gray-100;
  min-width: 80px;
}

.multiplechoice_option_editor .multiplechoice_letter {
  @apply text-gray-500;
}
.title-anim {
  @apply transition-all z-20;
}
.title-anim .edit-button {
  @apply opacity-100;
}

.title-anim.title-header {
  @apply top-3 left-20 absolute text-2xl cursor-default;
}
.title-anim.title-header .edit-button {
  @apply opacity-0;
}

.box-question .drag-handle {
  @apply transition-all opacity-0;
}
.box-question:hover .drag-handle {
  @apply transition-all opacity-100;
}

.box-question .add-content-box {
  @apply transition-all opacity-0;
}
.box-question:hover .add-content-box {
  @apply transition-all opacity-100;
}

.drag-handle {
  @apply h-6 w-5 shadow-sm bg-white rounded border border-gray-200 items-center flex justify-center text-center absolute -left-2;
  cursor: grab;
}

.view-builder .question-points:focus,
.view-builder .question-points:active {
  @apply ring-2 ring-offset-2 ring-teal-500 outline-none ring-opacity-90;
}
.exam-cover input {
  @apply outline-none;
}

input.question-points {
  @apply text-base px-2 py-1 rounded-lg bg-gray-100 w-16 text-xl text-right;
}

input.question-points-2 {
  @apply text-base px-2 py-1.5 rounded-lg bg-gray-100 text-xl text-left;
}

.dropdown.w-full .dropdown-trigger {
  width: 100% !important;
}
.dropdown .dropdown-content {
  @apply border-gray-200;
}
.multiplechoice_option_editor input,
.multiplechoice_option_editor input:active,
.multiplechoice_option_editor input:focus {
  @apply py-2 rounded-md outline-none;
}
.fw-multiplechoice .multiplechoice_option_editor.active {
  @apply ring-2 ring-offset-2 ring-teal-500 ring-opacity-90;
}

.question-container {
  min-height: 85px;
  @apply relative flex gap-5;
}

.loading-bar {
  width: 12%;
  animation-name: loading-bar-anim;
  animation-duration: 3s;
  animation-delay: 500ms;
}

@keyframes loading-bar-anim {
  from {
    width: 12%;
  }
  to {
    width: 80%;
  }
}

/* Chrome, Safari, Edge, Opera */
input.points_input::-webkit-outer-spin-button,
input.points_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.points_input[type='number'] {
  -moz-appearance: textfield;
}
</style>

<i18n>
  {
    "pt": {
      "multiple_choice": "Escolha múltipla",
      "text_area": "Pergunta aberta",
      "upload_file": "Upload de ficheiros",
      "text_content": "Conteúdo de texto"
    },
    "en": {
      "multiple_choice": "Multiple choice",
      "text_area": "Open question",
      "upload_file": "Upload files",
      "text_content": "Text content"
    }
  }
</i18n>
