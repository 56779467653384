<template>
  <div class="w-full">
    <div class="flex-shrink-0 p-4 border-b border-gray-100 flex flex-col gap-4">
      <fw-heading>Versões do documento</fw-heading>
      <div v-if="currentVersion?.key" class="flex gap-3">
        <fw-button
          v-if="currentVersion.state != 'draft'"
          class="flex-1"
          type="xlight"
          @click.native="$emit('snapshot', currentVersion.key)"
        >
          {{ $t('createVersion') }}
        </fw-button>

        <fw-button v-if="currentVersion.state == 'draft'" class="flex-1" type="primary" @click.native="publish()">
          {{ $t('publish') }}
        </fw-button>
      </div>
    </div>
    <div v-if="!loading" class="px-4 flex flex-col gap-2 pt-4">
      <RecordVersion
        v-for="version in versions"
        :key="version.key"
        :active="currentVersion && version.key == currentVersion.key"
        :version="version"
        @click.native="
          () => {
            if (version.key != currentVersion.key) changeVersion(version)
          }
        "
      />

      <template v-if="currentVersion?.key">
        <fw-panel-info
          v-if="currentVersion.state != 'draft' && currentVersion.pdf_file_status === 'error'"
          type="warning"
        >
          {{ $t('errorGeneratingPDF') }}
        </fw-panel-info>
        <RecordFileEntry
          v-else-if="currentVersion.state != 'draft' && currentVersion.pdf_file?.key"
          :key="currentVersion.pdf_file.key"
          :file="{ ...currentVersion.pdf_file, filename: `PDF v${currentVersion.index}` }"
          :can-edit="false"
          :can-remove="false"
          :can-preview="false"
          @download="downloadFile(currentVersion.pdf_file)"
        />

        <fw-button
          v-if="
            currentVersion.state == 'published' &&
              (currentVersion.pdf_file_status == 'error' || !currentVersion.pdf_file_status)
          "
          class="flex-1"
          type="xlight"
          @click.native="$emit('generate-pdf', { pageKey, versionKey: currentVersion.key })"
        >
          {{ $t('generatePDF') }}
        </fw-button>
      </template>
    </div>
    <div v-else class="px-4 flex flex-col gap-2">
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
      <div class="bg-gray-100 h-12 rounded-md animate-pulse"></div>
    </div>
  </div>
</template>
<script>
import RecordVersion from '@/fw-modules/fw-core-vue/pages/components/records/RecordVersion.vue'
import RecordFileEntry from '@/fw-modules/fw-core-vue/ui/components/form/RecordFileEntry'

import ServiceStorage from '@/fw-modules/fw-core-vue/storage/services/ServiceStorage'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  name: 'PanelSideVersions',
  components: {
    RecordVersion,
    RecordFileEntry
  },
  props: {
    currentVersion: {
      type: Object
    },
    pageKey: {
      type: String,
      required: true
    },
    versions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false
      // versions: []
    }
  },

  // created() {
  // console.log('PanelSideVersions created')
  // this.loadPageVersions()
  // },

  methods: {
    publish() {
      this.$emit('publish', this.currentVersion.key)
    },
    createVersion() {
      this.$emit('create-version')
    },
    changeVersion(version) {
      console.log('PanelSideVersions.changeVersion', version)
      this.$emit('change-version', version)
    },
    // loadPageVersions() {
    //   this.loading = true
    //   ServicePages.getAllPageVersions(this.pageKey)
    //     .then(result => {
    //       console.log('PanelSideVersions.loadPageVersions', result)
    //       this.versions = result.versions
    //     })
    //     .finally(() => {
    //       this.loading = false
    //     })
    // },
    async downloadFile(file) {
      const url = ServiceStorage.getFileUrl(file, this.$store.state.session.user.token)
      utils.downloadFile(url, file.filename)
    }
  }
}
</script>

<i18n lang="json">
{
  "en": {
    "createVersion": "Create a copy of this version",
    "publish": "Publish",
    "errorGeneratingPDF": "An error occurred while generating the PDF of this document",
    "generatePDF": "Generate PDF of this version"
  },
  "pt": {
    "createVersion": "Criar cópia desta versão",
    "publish": "Publicar",
    "errorGeneratingPDF": "Ocorreu um erro ao gerar o PDF deste documento",
    "generatePDF": "Gerar PDF desta versão"
  }
}
</i18n>
