var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('fw-panel',{attrs:{"title":"Respostas","counter":_vm.answers.length,"featured":""},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('div',{staticClass:"flex gap-5"},[_c('ContextualSearch',{attrs:{"type":"minimal","loading":_vm.loading,"filter-options":_vm.filters,"applied-filters":_vm.appliedFilters,"start-value":_vm.searchInput,"applied-user":_vm.selectedUser},on:{"search":_vm.search},scopedSlots:_vm._u([{key:"tags",fn:function(){return _vm._l((_vm.appliedFilters),function(filter,f){return _c('FilterTag',{key:'filter_' + f,attrs:{"text":_vm.getFilterText(filter),"show-close-button":true},on:{"close":function($event){return _vm.deleteFilter(f)}}})})},proxy:true}])}),(_vm.instance)?_c('b-dropdown',{attrs:{"aria-role":"list","position":"is-bottom-left"}},[_c('fw-button-dropdown',{attrs:{"slot":"trigger","aria-role":"listitem","type":"xlight","label":"Opções","chevron":false},slot:"trigger"},[_c('fw-icon-more',{staticClass:"w-5 h-5"})],1),_c('b-dropdown-item',{attrs:{"has-link":"","aria-role":"menuitem"}},[_c('a',{class:{
                'opacity-50 cursor-not-allowed':
                  _vm.instance == null || _vm.instance.status == 'running' || _vm.instance.answersFile == null
              },attrs:{"aria-label":"Download .csv"},on:{"click":function($event){return _vm.downloadAnswers()}}},[_vm._v(" Download .csv ")])])],1):_vm._e()],1)]},proxy:true},{key:"default",fn:function(){return [(_vm.instance.status == 'runnning')?_c('fw-message',{staticClass:"text-sm font-bold",attrs:{"type":"warning"}},[_vm._v("Este exame ainda está a decorrer...")]):_vm._e(),(_vm.answers.length == 0)?_c('div',{staticClass:"bg-white mb-7 rounded-xl mt-2 py-20 text-center text-gray-500 text-sm"},[_vm._v(" Sem respostas registadas. ")]):_c('div',[_c('div',{staticClass:"flex text-xs text-gray-500 font-medium gap-2 text-center mx-2 mb-2 mt-4"},[_c('div',{staticClass:"flex-1 text-left"},[_vm._v("Participante")]),_c('div',{staticClass:"w-44 flex-shrink-0"},[_vm._v("Estado")]),_c('div',{staticClass:"w-14 flex-shrink-0"},[_vm._v("Versão")]),_c('div',{staticClass:"w-14 flex-shrink-0"},[_vm._v("Respostas")]),_c('div',{staticClass:"w-24 flex-shrink-0"},[_vm._v("Pontuação")])]),_c('div',{staticClass:"bg-white rounded-xl text-sm"},_vm._l((_vm.answers),function(answer,a){return _c('router-link',{key:'answer_' + a,staticClass:"flex gap-2 items-center rounded-xl p-2 cursor-pointer font-medium text-center hover:font-bold hover:bg-gray-100",class:{ 'border-b border-gray-100': a != _vm.answers.length - 1 },attrs:{"to":_vm.goToAnswerUrl(answer, answer.formKey)}},[_c('Person',{staticClass:"flex-1",attrs:{"no-style":true,"paddingless":true,"person":answer.user,"size":"sm"}}),_c('div',{staticClass:"w-44 flex-shrink-0"},[_c('div',{class:{
                  'text-primary': answer.state == 'answering',
                  'text-gray-400': answer.state == 'noAnswer',
                  'text-gray-500': answer.state == 'closed',
                  'text-gray-500': answer.state == 'withdraw',
                  'text-gray-700': answer.state == 'ended'
                }},[_vm._v(" "+_vm._s(answer.state == 'answering' ? 'Em curso...' : answer.state == 'waiting' ? 'Em espera' : answer.state == 'noAnswer' ? 'Sem resposta' : answer.state == 'withdraw' ? 'Desistiu' : 'Terminado')+" ")])]),_c('div',{staticClass:"w-14 flex-shrink-0"},[_c('span',{staticClass:"px-2 py-1 min-w-8 rounded leading-6 text-center uppercase bg-gray-100"},[_vm._v(" "+_vm._s(_vm.versionMap[answer.formKey] || '?')+" ")])]),_c('div',{staticClass:"w-14 flex-shrink-0"},[_c('span',{class:{
                  'bg-yellow-500 bg-opacity-40 px-2 py-1 min-w-8 rounded': answer.summary.answers == 0
                }},[_vm._v(" "+_vm._s(answer.summary.answers)+" ")])]),_c('div',{staticClass:"w-24 flex-shrink-0",class:{
                'text-gray-400': answer.summary.scaleScore == null
              }},[_c('span',{class:{
                  'bg-yellow-500 bg-opacity-40 px-2 py-1 min-w-8 rounded':
                    answer.summary.scaleScore != null && answer.summary.scaleScore == 0
                }},[_vm._v(" "+_vm._s(answer.summary.scaleScore != null ? answer.summary.scaleScore : '-')+" ")])])],1)}),1)])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }