<template>
  <div
    class="p-3 items-center bg-white text-sm font-semibold rounded-md flex gap-2 text-gray-500"
    :class="{ 'cursor-pointer hover:bg-gray-100': !active }"
  >
    <div
      class="h-1.5 w-1.5 rounded-full"
      :class="{
        'bg-primary': active,
        'bg-gray-300': !active
      }"
    ></div>
    <div
      class="flex-1 line-clamp-1 overflow-hidden overflow-ellipsis"
      :class="{ 'text-gray-800': !!version?.title?.length }"
    >
      {{ title }}
    </div>
    <fw-tag size="xs" :type="stateColor">{{ stateLabel }}</fw-tag>
  </div>
</template>
<script>
export default {
  name: 'RecordVersion',
  props: {
    version: {
      type: Object,
      default: function() {
        return {}
      }
    },
    active: {
      type: Boolean,
      default: false
    },
    isDraft: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      statesLabes: {
        pt: {
          draft: 'Rascunho',
          review: 'Em revisão',
          closed: 'Bloqueado',
          published: 'Publicado',
          deleted: 'Eliminado',
          archived: 'Arquivado'
        },
        en: {
          draft: 'Draft',
          review: 'Under review',
          closed: 'Locked',
          published: 'Published',
          deleted: 'Deleted',
          archived: 'Archived'
        }
      }
    }
  },
  computed: {
    title() {
      if (this.version?.title?.length) return this.version.title
      else if (this.version?.index > 0) return `v${this.version.index}`
      return 'Sem título'
    },
    stateLabel() {
      return this.statesLabes[this.$i18n.locale][this.version.state]
    },
    stateColor() {
      return this.version.state === 'draft'
        ? 'light-orange'
        : this.version.state === 'published'
        ? 'light-primary'
        : this.version.state === 'closed'
        ? 'light-gray'
        : 'light-blue'
    }
  }
}
</script>
