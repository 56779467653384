<template>
  <div class="flex gap-2 h-full min-h-0 border-l border-gray-200">
    <div class="bg-gray-50 w-full flex-1 h-full min-h-0 flex flex-col">
      <div class="flex ring-1 ring-gray-200">
        <button
          v-if="false"
          :disabled="!editable"
          class="sidebar-button"
          :class="{ active: sidebar == 'editor' }"
          @click="$emit('tab', 'editor')"
        >
          <fw-icon-text-box class="h-5 w-5"></fw-icon-text-box>
        </button>
        <button class="sidebar-button" :class="{ active: sidebar == 'versions' }" @click="$emit('tab', 'versions')">
          <fw-icon-git-branch-line class="h-5 w-5" />
        </button>
        <button class="sidebar-button" :class="{ active: sidebar == 'comments' }" @click="$emit('tab', 'comments')">
          <fw-icon-discuss class="h-5 w-5"></fw-icon-discuss>
        </button>
        <button
          v-if="showTranslations"
          class="sidebar-button"
          :class="{ active: sidebar == 'translations' }"
          @click="$emit('tab', 'translations')"
        >
          <fw-icon-translate class="h-5 w-5"></fw-icon-translate>
        </button>
        <button
          v-if="canOpenSettings"
          class="sidebar-button"
          :class="{ active: sidebar == 'settings' }"
          @click="$emit('tab', 'settings')"
        >
          <fw-icon-settings class="h-5 w-5"></fw-icon-settings>
        </button>
      </div>
      <PanelSideEditor
        v-if="sidebar == 'editor'"
        :active="activeCommands"
        :selected-style="selectedCommand"
        :editor-command="editorCommand"
        :block-type="activeBlockType"
      ></PanelSideEditor>
      <PanelSideVersions
        v-else-if="sidebar == 'versions'"
        :myrole="myrole"
        :versions="versions"
        :current-version="currentVersion"
        :page-key="page.key"
        @generate-pdf="$emit('generate-pdf', $event)"
        @change-version="$emit('change-version', $event)"
        @snapshot="$emit('snapshot', $event)"
        @publish="$emit('publish', $event)"
      ></PanelSideVersions>
      <PanelSideTranslations
        v-else-if="sidebar == 'translations'"
        :myrole="myrole"
        :versions="versions"
        :current-version="currentVersion"
        @change-version="$emit('change-version', $event)"
        @snapshot="$emit('snapshot')"
        @new-language="$emit('new-language')"
      ></PanelSideTranslations>
      <PanelSideComments
        v-else-if="sidebar == 'comments'"
        :myrole="myrole"
        :version="currentVersion"
        :comments="comments"
        :users="users"
        @open-comment="$emit('open-comment', $event)"
        @new-comment="$emit('new-comment', $event)"
        @edit-comment="$emit('edit-comment', $event)"
        @delete-comment="$emit('delete-comment', $event)"
      />
      <PanelSideSettings
        v-else-if="sidebar == 'settings'"
        :page="page"
        :myrole="myrole"
        :translator="translator"
        :version="currentVersion"
        @import-file="$emit('import-file', $event)"
        @export-file="$emit('export-file', $event)"
        @share-document="$emit('share-document', $event)"
        @change-state="$emit('change-state', $event)"
        @delete-document="$emit('delete-document', $event)"
        @delete-version="$emit('delete-version', $event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  components: {
    PanelSideEditor: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideEditor.vue'),
    PanelSideVersions: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideVersions.vue'),
    PanelSideComments: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideComments.vue'),
    PanelSideSettings: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideSettings.vue'),
    PanelSideTranslations: () => import('@/fw-modules/fw-core-vue/pages/components/panels/PanelSideTranslations.vue')
  },
  props: {
    myrole: {
      type: String,
      required: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    activeCommands: {
      type: Object,
      default: () => ({})
    },
    selectedCommand: {
      type: String,
      default: ''
    },
    activeBlockType: {
      type: String,
      default: null
    },
    editorCommand: {
      type: Function,
      required: true
    },
    sidebar: {
      type: String,
      default: 'comments'
    },
    currentStyle: {
      type: String,
      default: 'paragraph'
    },
    versions: {
      type: Array,
      default: () => []
    },
    currentVersion: {
      type: Object,
      default: () => ({})
    },
    translator: {
      type: Boolean,
      default: false
    },
    users: {
      type: Object,
      default: () => ({})
    },
    comments: {
      type: Array,
      default: () => []
    },
    page: {
      type: Object,
      required: true
    },
    showTranslations: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    debugMode() {
      return Boolean(localStorage.getItem('fw-debug'))
    },
    canOpenSettings() {
      return (this.myrole == 'owner' || this.myrole == 'manager') && this.page.application != 'courses'
    }
  }
}
</script>
<style scoped>
.sidebar-button {
  transition: all 0.2s ease-in-out;
  @apply flex-1 border-b-2 px-2 pb-3 pt-4 flex items-center justify-center border-transparent cursor-pointer text-gray-400 hover:text-gray-600 hover:bg-gray-100;
}
.sidebar-button.active {
  @apply border-primary text-primary bg-primary bg-opacity-5;
}
</style>
