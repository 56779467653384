var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LayoutDashboardDefault',{staticClass:"group",attrs:{"dark":""}},[_c('div',{staticClass:"absolute top-5 right-5 flex gap-2.5 opacity-0 group-hover:opacity-100 transition-opacity duration-300"},[(_vm.exams.length)?_c('fw-button',{attrs:{"type":"dark","loading":_vm.loading,"disabled":_vm.loading},nativeOn:{"click":function($event){return _vm.removeAll.apply(null, arguments)}}},[_vm._v(" Apagar todos ")]):_vm._e(),_c('fw-button',{attrs:{"type":"dark","icon":"add","loading":_vm.loading,"disabled":_vm.loading || _vm.exams.length == 4},nativeOn:{"click":function($event){return _vm.addExam.apply(null, arguments)}}},[_vm._v(" Adicionar ")])],1),(_vm.loading)?_c('LoadingPage',{attrs:{"dark":true,"icon":"exam","title":"Exame Digital"}}):(!_vm.loading && _vm.exams.length == 0)?_c('div',{staticClass:"flex flex-col gap-10 items-center text-center justify-center w-full h-full text-gray-200"},[_c('div',[_c('fw-icon-uc-digital-building',{staticClass:"w-48 h-48 lg:w-96 lg:h-96"})],1),_c('div',{staticClass:"flex flex-col gap-2 items-center"},[_c('div',{staticClass:"text-lg font-bold opacity-50"},[_vm._v(_vm._s(_vm.$t('no_exams')))]),_c('div',{staticClass:"text-sm font-medium opacity-30"},[_vm._v(_vm._s(_vm.$t('no_exams_instructions'))+".")])])]):_vm._e(),(!_vm.loading && _vm.exams.length > 0)?_c('div',{staticClass:"justify-center items-center gap-10 w-full",class:{
      flex: _vm.exams.length < 3,
      'grid grid-cols-2': _vm.exams.length >= 3
    }},[_vm._l((_vm.exams),function(exam){return [_c('PanelExamInfo',{key:exam.key,class:{
          'w-[60rem] h-[48rem]': _vm.exams.length < 3,
          'w-full h-full': _vm.exams.length >= 3
        },attrs:{"instance":exam,"language":_vm.language}})]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }