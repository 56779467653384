<template>
  <section>
    <fw-heading size="xl">Pessoas</fw-heading>
    <fw-panel
      v-if="creator !== null"
      title="Responsável"
      boxed="xs"
      custom-class="bg-white"
      class="mt-5 mb-6"
      counter="1"
    >
      <template v-if="false && canEdit" #toolbar>
        <fw-button type="link" @click.native="choosePeople('creator')">Alterar</fw-button>
      </template>
      <div>
        <Person :key="creator.key" :person="creator" open-details-on-click :selectable="false" :clickable="true">
          <template #options>
            <fw-button v-if="canEdit && creator.key == loggedUser.key" type="link" @click.native="changeOwner()"
              >Alterar</fw-button
            >
          </template>
        </Person>
      </div>
    </fw-panel>
    <fw-panel title="Gestores" boxed="xs" custom-class="bg-white" class="mb-5 mt-2" :counter="managers.length">
      <template v-if="canEdit" #toolbar>
        <fw-button v-if="canEdit" type="link" @click.native="choosePeople('manager')">Adicionar</fw-button>
      </template>
      <fw-panel-info v-if="!managers || managers.length === 0" centered clean class="py-5"
        >Ainda não foram definidos gestores.</fw-panel-info
      >
      <ExpandList
        add-title="Adicionar Gestor"
        :empty="!managers || managers.length === 0"
        :show-add-items="canEdit && (!managers || managers.length === 0)"
        @add-element="choosePeople('manager')"
      >
        <template #list>
          <Person
            v-for="(person, p) in managers"
            :key="person.key"
            :class="{
              'border-b border-gray-100': p !== managers.length - 1
            }"
            open-details-on-click
            :person="person"
            :selectable="false"
            :clickable="true"
          >
            <template v-if="canEdit" #options>
              <b-dropdown :scrollable="true" :max-height="200" aria-role="list" position="is-bottom-left">
                <template #trigger="{}">
                  <fw-button size="sm" class="text-gray-400 text-sm">
                    <fw-icon-more class="h-5 w-5" />
                  </fw-button>
                </template>

                <b-dropdown-item aria-role="listitem">
                  <fw-button type="transparent" @click.native="deleteManager(person.key)"
                    ><fw-icon-trash class="h-5 w-5" /> Apagar</fw-button
                  >
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </Person>
        </template>
      </ExpandList>
    </fw-panel>

    <fw-panel title="Participantes" class="my-5" :counter="students.length">
      <template v-if="canEdit" #toolbar>
        <div class="flex gap-3">
          <div>
            <b-dropdown aria-role="list" position="is-bottom-left">
              <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                <fw-icon-more class="w-5 h-5" />
              </fw-button-dropdown>
              <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                <fw-button type="basic-action" expanded @click.native="modal = 'import_bulk'">
                  Importar lista de pessoas</fw-button
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div>
            <fw-button type="link" @click.native="choosePeople('students')">Adicionar</fw-button>
          </div>
        </div>
      </template>
      <fw-message v-if="access == 'public'" centered type="info" class="text-sm"
        >Este exame está definido em acesso <b>Aberto</b>. Qualquer utilizador poderá aceder ao exame desde que conheça
        a chave.</fw-message
      >
      <fw-message v-else centered type="info" class="text-sm"
        >Este exame está definido em acesso <b>Restrito</b>. Apenas os participantes na seguinte lista poderão aceder ao
        exame.</fw-message
      >
      <div class="bg-white rounded-lg mt-2">
        <fw-panel-info v-if="!students.length" centered clean class="py-5"
          >Ainda não foram associados participantes a exame.</fw-panel-info
        >
        <ExpandList
          add-title="Adicionar Participante"
          :empty="!students.effective || students.length < 1"
          :show-add-items="canEdit"
          @add-element="choosePeople('students')"
        >
          <template #list>
            <Person
              v-for="(person, p) in students"
              :key="person.key"
              :class="{
                'border-b border-gray-100': canEdit || p < students.length - 1
              }"
              open-details-on-click
              :person="person"
              :selectable="false"
              :clickable="true"
              @clicked="$emit('open-user', person)"
            >
              <template v-if="canEdit" #options>
                <b-dropdown aria-role="list" position="is-bottom-left">
                  <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                    <fw-icon-more class="w-5 h-5" />
                  </fw-button-dropdown>
                  <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                    <fw-button type="basic-action" expanded @click.native="deleteStudent(person.key)">
                      Remover</fw-button
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </Person>
          </template>
        </ExpandList>
      </div>
    </fw-panel>

    <fw-panel-info label="People data (raw)" class="my-5">
      <json-viewer :value="{ managers, students }"></json-viewer>
    </fw-panel-info>

    <b-modal
      :active="modal != null"
      scroll="keep"
      :can-cancel="true"
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="700"
      :on-cancel="closeModal"
      :custom-class="'rounded-buefy-modal'"
    >
      <ChoosePeopleModal
        v-if="modal == 'chose_people'"
        :title="selectPeopleTitle"
        :instructions="selectPeopleInstructions"
        :multiselect="multiselectPeople"
        :not-alowed-users="notAlowedUsers"
        :invite-people="false"
        :endpoint="searchEndpoint"
        @selected="selectedPeople"
        @close="closeModal"
      ></ChoosePeopleModal>
      <ModalImportBulkUsers
        v-if="modal == 'import_bulk'"
        :context="{ key: instance.key }"
        @import="addBulkparticipants"
        @close="closeModal"
      ></ModalImportBulkUsers>
    </b-modal>
  </section>
</template>

<script>
//import { RecycleScroller } from 'vue-virtual-scroller'
//import OptionsCard from '@/fw-modules/fw-core-vue/ui/components/form/OptionsCard'
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'
import FwMessage from '@/fw-modules/fw-core-vue/ui/components/text/FwMessage'
import ChoosePeopleModal from '@/fw-modules/fw-core-vue/ui/components/modals/ChoosePeopleModal'
import ModalImportBulkUsers from '@/fw-modules/fw-core-vue/ui/components/modals/ModalImportBulkUsers'

//import ChooseSecretariatModal from '@/components/modals/ChooseSecretariatModal'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'

export default {
  components: {
    //RecycleScroller,
    ExpandList,
    Person,
    ChoosePeopleModal,
    //OptionsCard,
    FwMessage,
    ModalImportBulkUsers
    //ChooseSecretariatModal,
  },
  props: {
    users: {
      type: Object,
      default: () => {}
    },

    instance: {
      type: Object,
      default: () => {}
    },

    canEdit: {
      type: Boolean,
      default: true
    },

    savingData: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      choosingPeopleType: null,
      currentSelectedPeople: [],
      usersList: [],
      students: [],
      managers: [],
      modal: null
      //creator: null,
      //public: true,
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    access() {
      return this.instance.options.is_public ? 'public' : this.instance.options.isPublic ? 'public' : 'list'
    },
    creator() {
      return this.users[this.instance.user_key] ?? null
    },
    selectPeopleTitle() {
      let titlesMap = {
        students: 'Escolher participantes no exame',
        managers: 'Escolher gestores',
        owner: 'Escolher responsável'
      }
      return titlesMap[this.choosingPeopleType] ? titlesMap[this.choosingPeopleType] : 'Escolher pessoa'
    },

    selectPeopleInstructions() {
      let instructionsMap = {
        students: 'Selecione os participantes com acesso ao exame:',
        managers: 'Selecione quem pode gerir o exame:',
        owner: 'Selecione o responsável pelo exame:'
      }
      return instructionsMap[this.choosingPeopleType]
        ? instructionsMap[this.choosingPeopleType]
        : 'Seleccione um utilizador'
    },

    multiselectPeople() {
      return this.choosingPeopleType != 'owner'
    },

    notAlowedUsers() {
      let userKeys = this.students.concat(this.managers).map(el => el.key)
      if (this.creator != null) {
        userKeys.push(this.creator.key)
      }

      return userKeys
    }
  },

  /*watch: {
    'procedure.vogals'(newValue) {
      console.log('changed procedure vogals', newValue)
      this.people.vogals = {
        effective: newValue.filter(el => !el.as_president_substitute),
        substitute: newValue.filter(el => el.as_president_substitute),
      }
    },
    'procedure.presidents'(newValue) {
      console.log('changed procedure presidents', newValue)
      this.people.presidents = newValue
    },
  },*/

  created() {
    this.students = this.instance.allowed_users.map(user_key => this.users[user_key])
    this.managers = Object.keys(this.instance.managers).map(user_key => this.users[user_key])
    /*this.people = {
      presidents: this.procedure.presidents,
      vogals: {
        effective: this.procedure.vogals ? this.procedure.vogals.filter(el => !el.as_president_substitute) : [],
        substitute: this.procedure.vogals ? this.procedure.vogals.filter(el => el.as_president_substitute) : [],
      },
      manager: this.procedure.manager,
    }

    this.applications = this.procedure.applications*/
  },

  methods: {
    changeOwner() {
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Confirmar',
        title: 'Alterar responsável',
        message: 'Atenção que pode perder o acesso a este exame caso não seja gestor.',
        onConfirm: async () => {
          this.choosePeople('owner')
        }
      })
    },
    async searchEndpoint(params) {
      return await FormServices.searchUsers(this.instance.key, params)
    },
    changeAccess(value) {
      if (value == 'public') {
        this.changeInstancePermission(true)
      } else {
        this.changeInstancePermission(false)
      }
    },
    async changeInstancePermission(is_public = true) {
      let instance = this.instance
      let title = instance.title
      if (typeof title['pt'] === 'undefined') {
        title['pt'] = ''
      }
      if (typeof title['en'] === 'undefined') {
        title['en'] = ''
      }
      instance.options.isPublic = is_public
      delete instance.options.is_public
      let result = await FormServices.updateInstance(
        instance.key,
        instance.forms.map(el => el.key),
        title,
        instance.options,
        instance.startDate,
        instance.duration
      )
      console.log(result)
      this.$emit('update-instance', result)
    },
    choosePeople(type) {
      this.choosingPeopleType = type
      this.modal = 'chose_people'
      this.currentSelectedPeople = []

      /*if (type === 'effective-vogals') {
        this.currentSelectedPeople = this.people.vogals.effective.map(el => el.key)
      } else if (type === 'substitute-vogals') {
        this.currentSelectedPeople = this.people.vogals.substitute.map(el => el.key)
      } else if (type === 'manager') {
        this.currentSelectedPeople = [this.people.manager]
      } else if (type === 'presidents') {
        this.currentSelectedPeople = this.people.presidents.map(el => el.key)
      }*/

      //students | managers

      console.log('currentSelectedPeople', this.currentSelectedPeople)
    },

    async deleteStudent(key) {
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Remover',
        message: 'Tem a certeza que pretende remover este participante da lista?',
        onConfirm: async () => {
          try {
            //remove from students list
            let result = await FormServices.removeStudentToInstance(this.instance.key, [key])
            console.log(result)
            this.students = this.students.filter(user => user.key != key)
            this.$buefy.snackbar.open({
              message: `Participante removido.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000
            })
          } catch (e) {
            console.error(e)
          }
        }
      })
    },

    deleteManager(key) {
      //remove from managers list
      this.$buefy.dialog.confirm({
        cancelText: 'Cancelar',
        confirmText: 'Confirmar',
        message: 'Tem a certeza que pretende remover este gestor?',
        onConfirm: async () => {
          try {
            //remove from students list
            let result = await FormServices.removeManagerToInstance(this.instance.key, [key])
            console.log(result)
            this.managers = this.managers.filter(user => user.key != key)
            this.$buefy.snackbar.open({
              message: `Gestor removido.`,
              type: 'is-primary',
              position: 'is-top-right',
              duration: 3000
            })
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    async updateOwner(selection) {
      try {
        let result = await FormServices.updateOwner(this.instance.key, selection[0].key)
        console.log(selection, result)
        this.$buefy.snackbar.open({
          message: `Responsável alterado.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 3000
        })
        //atualizar a página
        this.$router.push('/exams')
      } catch (e) {
        this.showError('Ocorreu um erro ao modificar o responsável')
        console.error(e)
      }
    },
    async updateStudents(selection) {
      try {
        let result = await FormServices.addStudentToInstance(
          this.instance.key,
          selection.map(el => el.key)
        )
        console.log(result)

        this.students = this.students.concat(selection)
        this.modal = null

        this.$buefy.snackbar.open({
          message: `Participantes adicionados.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 3000
        })
      } catch (e) {
        this.showError('Ocorreu um erro ao adicionar o(s) participante(s)')
        console.error(e)
      }
    },
    async updateManagers(selection) {
      try {
        let result = await FormServices.addInstancePermissionsByUserkey(
          this.instance.key,
          selection.map(el => el.key),
          {
            canSeeAnswers: true,
            startStopInstance: true,
            isAdmin: true
          }
        )
        console.log(result)
        this.managers = result.users //this.managers.concat(selection)
        this.$buefy.snackbar.open({
          message: `Gestores adicionados.`,
          type: 'is-primary',
          position: 'is-top-right',
          duration: 3000
        })
      } catch (e) {
        this.showError('Ocorreu um erro ao adicionar o(s) gestor(s)')
        console.error(e)
      }
    },
    addBulkparticipants(selection) {
      console.log('Add bulk: ', selection)
      this.updateStudents(selection.users)
    },
    selectedPeople(selection) {
      //, extra = {}
      console.log(`selectedPeople type:${this.choosingPeopleType}`, selection)
      switch (this.choosingPeopleType) {
        case 'students':
          this.updateStudents(selection)
          break
        case 'manager':
          this.updateManagers(selection)
          break
        case 'owner':
          this.updateOwner(selection)
          break
        default:
          console.log('not a type')
      }
    },

    showError(message = 'Ocorreu um erro. Tente de novo mais tarde ou contacte o suporte.') {
      this.$buefy.dialog.alert({
        message: message,
        type: 'is-danger',
        hasIcon: false,
        icon: 'times-circle',
        iconPack: 'fa',
        ariaRole: 'alertdialog',
        ariaModal: true
      })
    },

    closeModal() {
      this.modal = null
    },

    importBulkUsers() {
      this.modal = 'import_bulk'
    }
  }
}
</script>
