<template>
  <div>
    <fw-panel title="Respostas" :counter="answers.length" featured>
      <template #toolbar>
        <div class="flex gap-5">
          <ContextualSearch
            type="minimal"
            :loading="loading"
            :filter-options="filters"
            :applied-filters="appliedFilters"
            :start-value="searchInput"
            :applied-user="selectedUser"
            @search="search"
          >
            <template #tags>
              <FilterTag
                v-for="(filter, f) in appliedFilters"
                :key="'filter_' + f"
                :text="getFilterText(filter)"
                :show-close-button="true"
                @close="deleteFilter(f)"
              ></FilterTag>
            </template>
          </ContextualSearch>
          <b-dropdown v-if="instance" aria-role="list" position="is-bottom-left">
            <fw-button-dropdown slot="trigger" aria-role="listitem" type="xlight" label="Opções" :chevron="false">
              <fw-icon-more class="w-5 h-5" />
            </fw-button-dropdown>
            <b-dropdown-item has-link aria-role="menuitem">
              <a
                aria-label="Download .csv"
                :class="{
                  'opacity-50 cursor-not-allowed':
                    instance == null || instance.status == 'running' || instance.answersFile == null
                }"
                @click="downloadAnswers()"
              >
                Download .csv
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
      <template #default>
        <fw-message v-if="instance.status == 'runnning'" type="warning" class="text-sm font-bold"
          >Este exame ainda está a decorrer...</fw-message
        >
        <div v-if="answers.length == 0" class="bg-white mb-7 rounded-xl mt-2 py-20 text-center text-gray-500 text-sm">
          Sem respostas registadas.
        </div>
        <div v-else>
          <div class="flex text-xs text-gray-500 font-medium gap-2 text-center mx-2 mb-2 mt-4">
            <div class="flex-1 text-left">Participante</div>
            <div class="w-44 flex-shrink-0">Estado</div>
            <div class="w-14 flex-shrink-0">Versão</div>
            <div class="w-14 flex-shrink-0">Respostas</div>
            <div class="w-24 flex-shrink-0">Pontuação</div>
          </div>
          <div class="bg-white rounded-xl text-sm">
            <router-link
              v-for="(answer, a) in answers"
              :key="'answer_' + a"
              :class="{ 'border-b border-gray-100': a != answers.length - 1 }"
              class="flex gap-2 items-center rounded-xl p-2 cursor-pointer font-medium text-center hover:font-bold hover:bg-gray-100"
              :to="goToAnswerUrl(answer, answer.formKey)"
            >
              <Person :no-style="true" :paddingless="true" :person="answer.user" size="sm" class="flex-1" />
              <div class="w-44 flex-shrink-0">
                <div
                  :class="{
                    'text-primary': answer.state == 'answering',
                    'text-gray-400': answer.state == 'noAnswer',
                    'text-gray-500': answer.state == 'closed',
                    'text-gray-500': answer.state == 'withdraw',
                    'text-gray-700': answer.state == 'ended'
                  }"
                >
                  {{
                    answer.state == 'answering'
                      ? 'Em curso...'
                      : answer.state == 'waiting'
                      ? 'Em espera'
                      : answer.state == 'noAnswer'
                      ? 'Sem resposta'
                      : answer.state == 'withdraw'
                      ? 'Desistiu'
                      : 'Terminado'
                  }}
                </div>
              </div>
              <div class="w-14 flex-shrink-0">
                <span class="px-2 py-1 min-w-8 rounded leading-6 text-center uppercase bg-gray-100">
                  {{ versionMap[answer.formKey] || '?' }}
                </span>
              </div>
              <div class="w-14 flex-shrink-0">
                <span
                  :class="{
                    'bg-yellow-500 bg-opacity-40 px-2 py-1 min-w-8 rounded': answer.summary.answers == 0
                  }"
                >
                  {{ answer.summary.answers }}
                </span>
              </div>
              <div
                class="w-24 flex-shrink-0"
                :class="{
                  'text-gray-400': answer.summary.scaleScore == null
                }"
              >
                <span
                  :class="{
                    'bg-yellow-500 bg-opacity-40 px-2 py-1 min-w-8 rounded':
                      answer.summary.scaleScore != null && answer.summary.scaleScore == 0
                  }"
                >
                  {{ answer.summary.scaleScore != null ? answer.summary.scaleScore : '-' }}
                </span>
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </fw-panel>
  </div>
</template>
<script>
import ContextualSearch from '@/fw-modules/fw-core-vue/ui/components/search/ContextualSearch'
import FilterTag from '@/fw-modules/fw-core-vue/ui/components/text/FilterTag'
import FwMessage from '@/fw-modules/fw-core-vue/ui/components/text/FwMessage'
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import Person from '@/fw-modules/fw-core-vue/ui/components/cards/PersonBase'

export default {
  name: 'PanelExamEvaluation',
  components: {
    ContextualSearch,
    FilterTag,
    FwMessage,
    Person
  },
  props: {
    instance: {
      type: Object,
      required: true
    },
    users: {
      type: Object,
      required: true
    },
    language: {
      type: String,
      default: 'pt'
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activityData: [],
      activity: null,
      loading: false,
      loadingExportToCSV: false,
      searchInput: '',
      totalPending: 0,
      activeModal: null,
      page: 1,
      totalPages: 1,
      limit: 100,
      pagePending: 1,
      totalPagesPending: 1,
      appliedFilters: [],
      userKey: null,
      user: null, //use userKey to get user info (eg: user profile image)
      selectedUser: null,
      itemKey: null,
      itemType: null,
      filters: [
        {
          key: 'state',
          label: 'Estado',
          options: [
            {
              key: 'reset',
              label: 'Todos'
            },
            {
              key: 'noAnswer',
              label: 'Sem resposta'
            },
            {
              key: 'answering',
              label: 'A responder'
            },
            {
              key: 'closed',
              label: 'Entregue'
            }
          ]
        }
      ],
      sortBy: 'name',
      sortDirection: 'asc',
      answers: [],
      versionMap: {},
      realtimeDebouncer: null
    }
  },
  computed: {
    examWSMessages() {
      return this.$store.state.session.unreadExamWsMessages
    },
    startDate() {
      return this.instance.startDate != null ? new Date(this.instance.startDate) : null
    }
  },
  watch: {
    examWSMessages(newMessages) {
      if (this.realtimeDebouncer == null && newMessages.length > 0) {
        this.realtimeDebouncer = setTimeout(() => {
          console.log('examWSMessages changed', newMessages)
          //change user state or number of answers
          //if not found, load answers again
          let userfound = true
          for (let index = 0; index < newMessages.length; index++) {
            const message = newMessages[index]
            console.log('newMessage', message)
            if (message.type == 'updateStatus' && message.user_key) {
              let user = this.answers.find(x => x.user.key == message.user_key)
              if (user) {
                user.state = message.status
              } else {
                userfound = false
              }
            } else if (message.type == 'instanceUpdate' && message.instance_key == this.instance.key) {
              if (
                message.status === 'lock_answers' ||
                message.status === 'unlock_answers' ||
                message.status === 'publish' ||
                message.status === 'unpublish'
              )
                return
              this.instance.status = message.status == 'stop' ? 'ended' : 'running'
            } else if (message.type == 'newPage') {
              //page loaded
              let user = this.answers.find(x => x.user.key == message.user_key)
              if (user) {
                user.state = 'answering'
              } else {
                userfound = false
              }
            } else if (message.type == 'awnserUpdate') {
              let numberQuestions = 0
              let questionsKeys = Object.keys(message.page_awnser.data)
              for (let index = 0; index < questionsKeys.length; index++) {
                const element = message.page_awnser.data[questionsKeys[index]]
                if (element && element.length > 0) {
                  numberQuestions++
                }
              }
              console.log('Number questions', numberQuestions)
              let user = this.answers.find(x => x.user.key == message.user_key)
              if (user) {
                user['summary']['answers'] = numberQuestions
              } else {
                userfound = false
              }
            }
          }
          this.$store.commit('removeFromExamMessageQueue', newMessages.length)
          if (!userfound) {
            this.loadAnswers()
          }
          this.realtimeDebouncer = null
        }, 1000)
      }
    }
  },
  created() {
    let versionMap = {}
    // let c = 0
    let formkeyFilter = {
      key: 'formKey',
      label: 'Versão',
      options: [
        {
          key: 'reset',
          label: 'Todas as versões'
        }
      ]
    }
    for (var i = 0; i < this.instance.forms.length; i++) {
      versionMap[this.instance.forms[i].key] = this.instance.forms[i].letter // this.instance.formVersions[i]
      formkeyFilter.options.push({
        key: this.instance.forms[i].key,
        label: 'Versão ' + this.instance.forms[i].letter
      })
    }
    if (this.instance.forms.length > 1) {
      this.filters.push(formkeyFilter)
    }

    this.versionMap = versionMap
  },

  mounted() {
    this.loadAnswers()
  },
  methods: {
    downloadAnswers() {
      if (this.instance && this.instance.answersFile && this.instance.status != 'running') {
        let url = this.instance.answersFile.url_format
        url = url.replace('{TOKEN}', this.instance.answersFile.token)
        url = url.replace('{KEY}', this.instance.answersFile.key)
        url = url.replace('{FILENAME}', this.instance.answersFile.filename)
        window.open(url, '_blank')
      } else {
        this.$buefy.dialog.alert({
          title: 'Respostas não disponíveis',
          message: 'As respostas só estão disponíveis após o exame terminar.',
          confirmText: 'Ok'
        })
      }
    },
    goToAnswerUrl(answer, formkey) {
      return {
        name: 'exams-manage-editor-version',
        params: {
          id: this.instance.key,
          versionid: formkey
        },
        query: {
          answers: answer.user.key
        }
      }
    },
    async loadAnswers() {
      if (this.instance.status != 'draft') {
        this.loading = true
        console.log('loading answers')
        try {
          let result = await FormServices.getAnswers(
            this.instance.key,
            true,
            this.sortBy,
            this.sortDirection,
            this.searchInput.length > 0 ? this.searchInput : null,
            []
          )
          this.answers = result.answers
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }
    },
    getFilterText(key) {
      var split = key.split(':')
      for (var i = 0; i < this.filters.length; i++) {
        if (this.filters[i].key == split[0]) {
          for (var j = 0; j < this.filters[i].options.length; j++) {
            if (this.filters[i].options[j].key == split[1]) {
              return this.filters[i].options[j].label
            }
          }
        }
      }
      return ''
    },
    deleteFilter(index) {
      this.appliedFilters.splice(index, 1)
      this.search()
    },
    async search(searchCriteria) {
      /*
       term: this.searchTerm,
        filters: this.selectedFilters,
        orderBy: this.orderItemsBy != null ? this.orderItemsBy.key : null,
        orderDirection: this.orderItemsBy != null ? this.orderDirection : null,
        user: this.user,
        dates: this.dates,
      */

      console.log('Searching...')
      console.log(searchCriteria)
      this.loading = true
      this.appliedFilters = searchCriteria.filters
      try {
        let result = await FormServices.getAnswers(
          this.instance.key,
          true,
          this.sortBy,
          this.sortDirection,
          searchCriteria.term.length > 0 ? searchCriteria.term : null,
          searchCriteria.filters
        )
        console.log(result)
        this.answers = result.answers
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    start() {
      this.$emit('start-exam')
    }
  }
}
</script>

<i18n>
  {
    "en": {
      "status": {
        "running": "In progress",
        "waiting": "Wait for the start of the exam",
        "ended": "Exam ended"
      },
      "action": {
        "reopen": "Reopen",
        "continue": "Continue",
        "start": "Start exam",
        "wait": "Wait"
      },
      "metadata": {
        "examkey": "Exame Key",
        "sections": "Sections",
        "questions": "Questions",
        "duration": "Duration",
        "predictedStartTime": "Predicted start time",
        "predictedStartDate": "Predicted start date"
      },
      "instructions": {
        "attention": "Attention",
        "negativePointsLimit": "Selecting wrong options take points from the question",
        "negativePoints": "Selecting wrong options take points from the exam",
        "points": "Points",
        "autoMonitor": "This exam uses automated monitoring tech",
        "knowmore": "Saber mais",
        "examcreatedby": "Exam created by",
        "examdynamizedby": "Exam dynamized by",
        "yourid": "Your id"
      }
    },
    "pt": {
      "status": {
        "running": "Em curso",
        "waiting": "Aguarde pelo exame começar",
        "ended": "Exame terminado"
      },
      "action": {
        "reopen": "Reabrir",
        "continue": "Continuar",
        "start": "Abrir Exame",
        "wait": "Aguarde"
      },
      "metadata": {
        "examkey": "Chave do exame",
        "sections": "Secções",
        "questions": "Questões",
        "duration": "Duração",
        "predictedStartTime": "Início previsto",
        "predictedStartDate": "Data prevista"
      },
      "instructions": {
        "attention": "Atenção",
        "negativePointsLimit": "Selecionar opções erradas descontam na própria pergunta",
        "negativePoints": "Selecionar opções erradas descontam",
        "points": "Pontuação",
        "autoMonitor": "Este exame usa tecnologia de monitorização automática",
        "knowmore": "Saber mais",
        "examcreatedby": "Exame criado por",
        "examdynamizedby": "Exame dinamizado por",
        "yourid": "A sua identificação"
      }
    }
  }
  </i18n>
