<template>
  <LayoutDashboardDefault dark class="group">
    <div class="absolute top-5 right-5 flex gap-2.5 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
      <fw-button v-if="exams.length" @click.native="removeAll" type="dark" :loading="loading" :disabled="loading">
        Apagar todos
      </fw-button>
      <fw-button
        @click.native="addExam"
        type="dark"
        icon="add"
        :loading="loading"
        :disabled="loading || exams.length == 4"
      >
        Adicionar
      </fw-button>
    </div>

    <LoadingPage v-if="loading" :dark="true" icon="exam" title="Exame Digital"></LoadingPage>

    <div
      v-else-if="!loading && exams.length == 0"
      class="flex flex-col gap-10 items-center text-center justify-center w-full h-full text-gray-200"
    >
      <div>
        <fw-icon-uc-digital-building class="w-48 h-48 lg:w-96 lg:h-96" />
      </div>
      <div class="flex flex-col gap-2 items-center">
        <div class="text-lg font-bold opacity-50">{{ $t('no_exams') }}</div>
        <div class="text-sm font-medium opacity-30">{{ $t('no_exams_instructions') }}.</div>
      </div>
    </div>

    <div
      v-if="!loading && exams.length > 0"
      class="justify-center items-center gap-10 w-full"
      :class="{
        flex: exams.length < 3,
        'grid grid-cols-2': exams.length >= 3
      }"
    >
      <template v-for="exam in exams">
        <PanelExamInfo
          :key="exam.key"
          :instance="exam"
          :language="language"
          :class="{
            'w-[60rem] h-[48rem]': exams.length < 3,
            'w-full h-full': exams.length >= 3
          }"
        />
      </template>
    </div>
  </LayoutDashboardDefault>
</template>

<script>
import FormServices from '@/fw-modules/fw-core-vue/exams/services/ServiceExams'
import PanelExamInfo from '../components/panels/PanelExamInfo.vue'
import LoadingPage from '@/fw-modules/fw-core-vue/ui/components/loading/LoadingPage'
import LayoutDashboardDefault from '@/fw-modules/fw-core-vue/ui/components/layouts/LayoutDashboardDefault'

export default {
  name: 'ViewExamProjectionInfo',
  components: {
    PanelExamInfo,
    LoadingPage,
    LayoutDashboardDefault
  },

  data() {
    return {
      instance: null,
      // We use this for a live update of the remaining time,
      // the PanelDashboardControls will update the instance.remainingTime
      remainingTime: 0,
      permissions: null,
      users: null,
      loading: true,
      answers: [],
      activity: [],
      language: 'pt',
      versions: [],
      pendingActivityMessages: [],
      versionsMap: {}, // Here we store the index for each formID
      // Activity that should be shown in the main activity section
      userModalKey: null, // Data to show in the modal
      screenHeight: 500,
      exams: []
    }
  },

  computed: {
    showUserModal() {
      return this.userModalKey !== null
    },
    userModalData() {
      return this.userModalKey !== null ? this.answers.find(answer => answer.user.key == this.userModalKey) : null
    },
    userActivity() {
      return this.activity.filter(activity => activity.by_user_key == this.userModalKey)
    },
    waitingForUsers() {
      return this.instance.status == 'waiting' || this.instance.status == 'scheduled' || this.instance.status == 'draft'
    },
    socketId() {
      return this.$store.state.socket.connectionId
    },
    instanceID() {
      return this.$route.params.id ? this.$route.params.id : null
    },
    examWSMessages() {
      return this.$store.state.session.unreadExamWsMessages
    }
  },

  mounted() {
    console.log('mounted')

    // Get exam keys in the url
    let url_keys = this.$route.query.keys
    // Local storage keys
    let local_storage_keys = localStorage.getItem('exam_info_keys')

    if (url_keys) {
      if (local_storage_keys) {
        let keys = local_storage_keys.split(',')

        // Add only the new keys
        if (keys.indexOf(url_keys) == -1) {
          keys = keys.concat(url_keys.split(','))
          localStorage.setItem('exam_info_keys', keys.join(','))
        }
      } else {
        localStorage.setItem('exam_info_keys', url_keys)
      }
    }
    this.loadExamsInfo()
  },

  methods: {
    removeAll() {
      this.$buefy.dialog.confirm({
        title: 'Remover painéis',
        message: 'Tem a certeza que deseja remover todos os painéis informativos?',
        confirmText: 'Remover',
        cancelText: 'Cancelar',
        onConfirm: async () => {
          localStorage.removeItem('exam_info_keys')
          localStorage.removeItem('exam_info_codes')
          this.exams = []
        }
      })
    },
    addExam() {
      //ask for exam 6 digit code
      this.$buefy.dialog.prompt({
        title: 'Adicionar Exame',
        message: 'Insira o código de 6 dígitos do exame',
        inputAttrs: {
          maxlength: 6
        },
        confirmText: 'Adicionar',
        cancelText: 'Cancelar',
        onConfirm: async value => {
          let instance_code = localStorage.getItem('exam_info_codes')
          if (instance_code) {
            let codes = instance_code.split(',')
            if (codes.indexOf(value) == -1) {
              codes.push(value)
              localStorage.setItem('exam_info_codes', codes.join(','))
            }
          } else {
            localStorage.setItem('exam_info_codes', value)
          }
          this.loadDashboard()
        }
      })
    },
    async loadExamsInfo() {
      console.log('loadExamsInfo')
      try {
        await this.loadDashboard()
      } catch (e) {
        console.error(e)
      } finally {
        // Force some delay to show the loading screen
        setTimeout(() => {
          this.loading = false
        }, 1250)
      }
    },
    async loadDashboard() {
      this.exams = []
      let instance_key = localStorage.getItem('exam_info_keys')
      let instance_code = localStorage.getItem('exam_info_codes')
      console.log('instance_key', instance_key)
      console.log('instance_code', instance_code)
      let result = await FormServices.getProjectionInfo(
        instance_key ? instance_key.split(',') : [],
        instance_code ? instance_code.split(',') : []
      )
      console.log('getProjectionInfo DATA', result)
      Object.entries(result).forEach(([, value]) => {
        this.exams.push(value)
      })
    }
  }
}
</script>

<i18n>
  {
    "pt": {
      "examNotOpen": "O exame ainda não foi aberto a participantes.",
      "loading": "A carregar...",
      "error": "Ocorreu um erro ao carregar o exame",
      "no_exams": "Sem exames para apresentar",
      "no_exams_instructions": "Clique em adicionar para projectar a informação de um exame"
    },
    "en": {
      "examNotOpen": "The exam was not open yet.",
      "loading": "Loading...",
      "error": "There was an error loading the exam",
      "no_exams": "No exam instance to show",
      "no_exams_instructions": "Click to add an exam code to show the information panel"
    }
  }
  </i18n>
